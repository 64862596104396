import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  InputGroup,
} from "@blueprintjs/core";
import { useAddEditMainModules } from "Hooks/api/courses/mainModules";
import { useError } from "Hooks/common";
import { useAppSelector } from "Hooks/redux";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { TСourseStatus } from "Types/common";

export interface IEditDataMainModules {
  id?: number;
  title: string;
  status: TСourseStatus;
  regionId: number;
}

interface IModalModules {
  editData?: IEditDataMainModules;
  setEditData: (editData: IEditDataMainModules | undefined) => void;
}

export const ModalMainModules: FC<IModalModules> = ({
  editData,
  setEditData,
}) => {
  const { id: courseId } = useParams<string>();

  const regions = useAppSelector((s) => s.App.regions);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (editData && courseId) {
      mutate({ courseId: courseId, data: editData });
      setErrors([]);
    }
  };

  const onClickCloseModal = () => {
    setEditData(undefined);
  };

  const { mutate, errors, setErrors, isDisabled } =
    useAddEditMainModules(onClickCloseModal);

  const error = useError(errors);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (editData) {
      setEditData({ ...editData, [e.target.id]: e.target.value });
    }
  };

  const onSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (editData) {
      setEditData({ ...editData, [e.target.id]: e.target.value });
    }
  };

  return (
    <Dialog
      isOpen={!!editData}
      title={
        editData?.id ? "Edit Main Module #" + editData.id : "Add Main Module"
      }
      onClose={onClickCloseModal}
    >
      <form onSubmit={onSubmit}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={"Title"} {...error("title")} labelFor={"title"}>
            <InputGroup
              disabled={isDisabled}
              value={editData?.title || ""}
              onChange={onChange}
              id={"title"}
              {...error("title")}
            />
          </FormGroup>

          <FormGroup label={"Status"} {...error("status")} labelFor={"status"}>
            <HTMLSelect
              id="status"
              fill
              value={editData?.status}
              onChange={onSelect}
              disabled={isDisabled}
              {...error("status")}
            >
              <option value={"active"}>Active</option>
              <option value={"draft"}>Draft</option>
              <option value={"closed"}>Closed</option>
            </HTMLSelect>
          </FormGroup>

          <FormGroup
            label={"Region"}
            {...error("regionId")}
            labelFor={"regionId"}
          >
            <HTMLSelect
              id="regionId"
              {...error("regionId")}
              fill
              value={editData?.regionId.toString()}
              onChange={onSelect}
              disabled={isDisabled}
            >
              {regions.map((item, index) => (
                <option value={item.id} key={"langMainInfo" + index}>
                  {item.title}
                </option>
              ))}
            </HTMLSelect>
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={onClickCloseModal}
              intent={"danger"}
              type={"button"}
              disabled={isDisabled}
            >
              Close
            </Button>
            <Button icon={"saved"} type={"submit"} disabled={isDisabled}>
              {editData?.id ? "Save" : "Add Main Module"}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
