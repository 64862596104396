import { FilesServices } from "Helpers/api/Courses/files";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { IValidError } from "Types/common";

interface IVariables {
  data: any;
  courseId: string;
}

export const useFileSave = (closeModal: any) => {
  const queryClient = useQueryClient();

  const [errors, setErrors] = useState<IValidError[]>([]);
  const [persent, setPersent] = useState<undefined | number>();

  const { isLoading, mutate } = useMutation(
    ({ data, courseId }: IVariables) => {
      if (data.id) {
        // return FilesServices.updateContent({
        //   courseId,
        //   data,
        //   mainModuleId,
        //   any: { setPersent },
        // });
      }
      return FilesServices.createFile(courseId, data, setPersent);
    },
    {
      onMutate: () => {
        setErrors([]);
        setPersent(undefined);
      },
      onSuccess: (courseId) => {
        queryClient.refetchQueries(["courses", courseId]);

        setPersent(undefined);
        closeModal();
      },
      onError: (errorsRes: IValidError[]) => {
        if (Array.isArray(errorsRes)) {
          setErrors(errorsRes);
        }
        setPersent(undefined);
      },
    }
  );

  return { errors, isDisabled: isLoading, mutate, setErrors, persent };
};

interface IVariablesDel {
  courseId: string;
  fileId: string;
}

export const useDeleteFile = () => {
  const queryClient = useQueryClient();

  const [errors, setErrors] = useState<IValidError[]>([]);

  const { isLoading, mutate } = useMutation(
    ({ courseId, fileId }: IVariablesDel) =>
      FilesServices.deleteFile(courseId, fileId),
    {
      onSuccess: (courseId) => {
        queryClient.refetchQueries(["courses", courseId]);
      },
      onError: (errorsRes: IValidError[]) => {
        if (Array.isArray(errorsRes)) {
          setErrors(errorsRes);
        }
      },
    }
  );

  return { errors, isDisabled: isLoading, mutate, setErrors };
};
