import axios, { AxiosError } from "axios";
import { IAddCourse } from "Componens/courses/modalMainCourse/constants";
import { API_COURSES, API_COURSES_ID } from "Constants/API/Courses";
import { HttpHeadersAuthorization } from "Helpers/common";
import { ICourseAll, ICourseMain } from "Types/courses";
import { ISuccessRes, IValidErrorRes } from "Types/responce";

export const CoursesServices = {
  async getCourseList() {
    return axios
      .get<ISuccessRes<ICourseMain[]>>(API_COURSES, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => res.data.data)
      .catch((e) => {
        throw e;
      });
  },
  async addCurse(data: IAddCourse) {
    return axios
      .post<ISuccessRes>(API_COURSES, data, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => res.data.data)
      .catch((e: AxiosError<IValidErrorRes>) => {
        if (e.response?.status === 406) {
          throw e.response.data.errors;
        }
        throw e;
      });
  },
  async updateCourse(data: IAddCourse) {
    return axios
      .put<ISuccessRes>(API_COURSES_ID(data?.id as number), data, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => res.data.data)
      .catch((e: AxiosError<IValidErrorRes>) => {
        if (e.response?.status === 406) {
          throw e.response.data.errors;
        }
        throw e;
      });
  },
  async getCourse(id: string | number) {
    return axios
      .get<ISuccessRes<ICourseAll>>(API_COURSES_ID(id), {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => res.data.data)
      .catch((e) => {
        throw e;
      });
  },
};
