import { Button, Card, FormGroup, InputGroup } from "@blueprintjs/core";
import { Icon } from "Componens/common/Icon";
import { useLogin } from "Hooks/api/useLogin";
import React, { FC, useCallback, useState } from "react";

import styles from "Styles/pageStyles/login.module.css";
import { ILogin } from "Types/login";

export const LoginPage: FC = () => {
  const [data, setData] = useState<ILogin>({ login: "", password: "" });

  const { isDisabled, mutate, errors } = useLogin();

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const onSubmitLoginData: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    mutate(data);
  };

  const error = useCallback(
    (name: string) => {
      const error = errors.find((e) => e.name === name);
      if (error) {
        return { helperText: error.message, intent: "danger" as any };
      }
      return;
    },
    [errors]
  );

  return (
    <div className={styles["login"]}>
      <Card className={styles["login_form"]}>
        <form onSubmit={onSubmitLoginData}>
          <div className={styles["login_logo"]}>
            <Icon icon="logo" />
          </div>
          <div>
            <FormGroup
              label={"Login"}
              intent={"danger"}
              className={styles["login__input"]}
              {...error("login")}
            >
              <InputGroup
                disabled={isDisabled}
                onChange={onChange}
                id={"login"}
                type={"email"}
                {...error("login")}
              />
            </FormGroup>
          </div>
          <div>
            <FormGroup
              label={"Password"}
              intent={"danger"}
              className={styles["login__input"]}
              {...error("password")}
            >
              <InputGroup
                disabled={isDisabled}
                onChange={onChange}
                id={"password"}
                type={"password"}
                {...error("password")}
              />
            </FormGroup>
          </div>
          <div className={styles["login_btn_row"]}>
            <Button intent={"primary"} type="submit" loading={isDisabled}>
              Вход
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
};
