import {
  Button,
  Card,
  // HTMLSelect,
  HTMLTable,
  InputGroup,
} from "@blueprintjs/core";
import { Loading } from "Componens/common";
import { URL_USERS_ID } from "Constants/URL";
import { DateToFormat } from "Helpers/common";
import { useUsers } from "Hooks/api/users/useUsers";
import { FC, useState } from "react";
import { Link } from "react-router-dom";

import styles from "Styles/pageStyles/list.module.css";
import { IUserFull } from "Types/users";

export const UsersPages: FC = () => {
  const [email, setEmail] = useState("");

  const { data, isLoading } = useUsers({ email, page: 1, limit: 25 });

  return (
    <div>
      <Card>
        <div className={styles["row"]}>
          <h1>Users {data?.meta && `(${data.meta.allCount})`}</h1>
          <div>
            <InputGroup
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        {isLoading ? (
          <Loading fullScreen />
        ) : (
          <HTMLTable className={styles["table"]}>
            <thead>
              <tr>
                <th>id</th>
                <th>Name</th>
                <th>lastName</th>
                <th>Email</th>
                <th>confirmed Email</th>
                <th>Created at</th>
                <th>Updated at</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((user, index) => (
                <TableItem key={index + "user"} {...user} />
              ))}
            </tbody>
          </HTMLTable>
        )}
      </Card>
    </div>
  );
};

const TableItem: FC<IUserFull> = ({
  id,
  name,
  lastName,
  email,
  createdAt,
  updatedAt,
  confirmedEmail,
}) => {
  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{lastName}</td>
      <td>{email}</td>
      <td>{confirmedEmail ? "Yes" : "No"}</td>
      <td>{DateToFormat(createdAt)}</td>
      <td>{DateToFormat(updatedAt)}</td>
      <td>
        <Link to={URL_USERS_ID(id)}>
          <Button icon={"arrow-right"} intent={"none"} minimal />
        </Link>
      </td>
    </tr>
  );
};
