import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  InputGroup,
} from "@blueprintjs/core";
import { useDeleteModule } from "Hooks/api/courses/modules";
import { useError } from "Hooks/common";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { IValidError } from "Types/common";
import { ICourseModuleEdit } from "../types";

interface IAddEditModule {
  submitData: (data: ICourseModuleEdit) => void;
  data?: ICourseModuleEdit;
  setEditData: React.Dispatch<
    React.SetStateAction<ICourseModuleEdit | undefined>
  >;
  lengthModules: number;
  errors: IValidError[];
  isDisabledSave: boolean;
}

export const AddEditModule: FC<IAddEditModule> = ({
  submitData,
  data,
  setEditData,
  lengthModules,
  isDisabledSave,
  errors,
}) => {
  const onCloseModal = () => {
    setEditData(undefined);
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!data) return;
    submitData(data);
  };

  const error = useError(errors);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (data) {
      setEditData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const onSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (data) {
      setEditData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const arrayOprionsNumberItem = useMemo(
    () =>
      Array.from(
        { length: data?.id === "new" ? lengthModules + 1 : lengthModules },
        (_, n) => n + 1
      ),
    [lengthModules, data?.id]
  );

  const { mutate: deleteModule, isDisabled: isDisabledDelete } =
    useDeleteModule(onCloseModal);

  const { id: idCourse, idModule } = useParams<string>();

  const onClickDelete = () => {
    if (!data || data.id === "new" || !idCourse || !idModule) return;
    deleteModule({
      moduleId: data.id,
      courseId: idCourse,
      mainModuleId: idModule,
    });
  };

  const isDisabled = useMemo(
    () => isDisabledSave || isDisabledDelete,
    [isDisabledSave, isDisabledDelete]
  );

  return (
    <Dialog
      isOpen={!!data}
      onClose={onCloseModal}
      title={
        (data?.id as unknown as string) === "new" ? "New Module" : "Edit module"
      }
    >
      <form onSubmit={onSubmit}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={"Title"} {...error("title")} labelFor={"title"}>
            <InputGroup
              disabled={isDisabled}
              value={data?.title || ""}
              onChange={onChange}
              id={"title"}
              {...error("title")}
            />
          </FormGroup>
          {/* descr */}
          <FormGroup label={"Status"} {...error("status")} labelFor={"status"}>
            <HTMLSelect
              id="status"
              fill
              value={data?.status}
              onChange={onSelect}
              disabled={isDisabled}
              {...error("status")}
            >
              <option value={"active"}>Active</option>
              <option value={"draft"}>Draft</option>
              <option value={"closed"}>Closed</option>
            </HTMLSelect>
          </FormGroup>
          {arrayOprionsNumberItem.length > 1 && (
            <FormGroup
              label={"Number"}
              {...error("itemNumber")}
              labelFor={"itemNumber"}
            >
              <HTMLSelect
                id="itemNumber"
                fill
                value={data?.itemNumber}
                onChange={onSelect}
                disabled={isDisabled}
                {...error("itemNumber")}
              >
                {arrayOprionsNumberItem.map((item) => (
                  <option value={item} key={"optionItem" + item}>
                    {item}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {data?.id !== "new" && (
              <Button
                onClick={onClickDelete}
                intent={"danger"}
                type={"button"}
                disabled={isDisabled}
                icon="trash"
              >
                Delete
              </Button>
            )}
            <Button icon={"saved"} type={"submit"} disabled={isDisabled}>
              {(data?.id as unknown as string) !== "new"
                ? "Save"
                : "Add Module"}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
