import axios, { AxiosError } from "axios";
import {
  API_ADD_ADMIN,
  API_ADMINS,
  API_ADMIN_ID,
  API_ADMIN_ID_EDIT,
} from "Constants/API/Admins";
import { HttpHeadersAuthorization } from "Helpers/common";
import { IAddAdmin } from "Pages/AdminPages/AdminsPages/AddAdminPage";
import { IUser } from "Types/login";
import { ISuccessRes, IValidErrorRes } from "Types/responce";

export const AdminsService = {
  async getAdminList() {
    return axios
      .get<ISuccessRes<IUser[]>>(API_ADMINS, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((e) => {
        throw e;
      });
  },
  async getAdmin(id: string) {
    return axios
      .get<ISuccessRes<IUser>>(API_ADMIN_ID(id), {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((e) => {
        throw e;
      });
  },
  async editAdmin(id: string, data: IUser) {
    return axios
      .put<ISuccessRes>(API_ADMIN_ID_EDIT(id), data, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => {
        return id;
      })
      .catch((e: AxiosError<IValidErrorRes>) => {
        throw e.response?.data.errors;
      });
  },
  async addAdmin(data: IAddAdmin) {
    return axios
      .post<ISuccessRes>(API_ADD_ADMIN, data, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((e: AxiosError<IValidErrorRes>) => {
        throw e.response?.data.errors;
      });
  },
};
