import { useAppSelector } from "Hooks/redux";
import { useCallback, useMemo } from "react";
import { IValidError } from "Types/common";

export const useError = (errors: IValidError[]) => {
  const error = useCallback(
    (name: string) => {
      const error = errors.find((e) => e.name === name);
      if (error) {
        return { helperText: error.message, intent: "danger" as any };
      }
      return;
    },
    [errors]
  );
  return error;
};

export const useGetRegionName = (regionId: number) => {
  const regions = useAppSelector((s) => s.App.regions);

  const regionName = useMemo(() => {
    return regions.find((item) => item.id === regionId)?.title || "";
  }, [regionId, regions]);

  return regionName;
};
