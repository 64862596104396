import { SupportServices } from "Helpers/api/Support";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TSupportStatus } from "Types/common";

export const useSupports = (status?: TSupportStatus) => {
  const { data, isError, isLoading } = useQuery(
    ["support", "list", status],
    () => SupportServices.getSupports(status)
  );

  return { data, isError, isLoading };
};

export const useSupport = (id: string) => {
  const { data, isError, isLoading } = useQuery(
    ["support", id],
    () => SupportServices.getSupport(id),
    {
      enabled: !!id,
    }
  );
  return { data, isError, isLoading };
};

export const useSupportUpdate = (id: string) => {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(
    (status: TSupportStatus) => SupportServices.updateStatus(id, status),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["support", id]);
      },
    }
  );
  return { isDiabled: isLoading, mutate };
};
