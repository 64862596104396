import { Card, Tab, Tabs } from "@blueprintjs/core";
import { RegionsSettings } from "Componens/settings";
import { useAppDispatch } from "Hooks/redux";
import { FC, useEffect, useState } from "react";

import styles from "Styles/pageStyles/admins/settings.module.css";

export const SettingsPage: FC = () => {
  const [tab, setTab] = useState("regions");

  const onChangeTabs = (tabSelect: string) => {
    setTab(tabSelect);
  };

  const { getRegions } = useAppDispatch();

  useEffect(() => {
    getRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className={styles["card"]}>
      <h1>Settings</h1>
      <Tabs
        id="TabsExample"
        onChange={onChangeTabs}
        selectedTabId={tab}
        renderActiveTabPanelOnly
        vertical
      >
        <Tab
          id="regions"
          title="Regions"
          panelClassName={styles["panel"]}
          panel={<RegionsSettings />}
        />
        <Tab
          id="paymentSystems"
          title="Payment Systems"
          panel={<div>Payment Systems</div>}
        />
      </Tabs>
    </Card>
  );
};
