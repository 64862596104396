import { Button } from "@blueprintjs/core";
import { Status } from "Componens/common";
import { URL_SUPPORT_ID } from "Constants/URL";
import moment from "moment";
import { FC } from "react";
import { Link } from "react-router-dom";
import { ISupportItem } from "Types/support";

export const SupportItem: FC<ISupportItem> = ({
  id,
  status,
  userId,
  name,
  createdAt,
}) => {
  return (
    <tr>
      <td>{id}</td>
      <td>{name || userId}</td>
      <td>{moment(createdAt).format("DD.MM.YYYY")}</td>
      <td>
        <Status
          statusCustom={status}
          TagsIntentCustom={{
            new: "primary",
            work: "warning",
            closed: "none",
            spam: "danger",
          }}
          TagNamesCustom={{
            new: "New",
            work: "Work",
            closed: "Closed",
            spam: "Spam",
          }}
        />
      </td>
      <td>
        <Link to={URL_SUPPORT_ID(id)}>
          <Button icon={"arrow-right"} intent={"none"} minimal />
        </Link>
      </td>
    </tr>
  );
};
