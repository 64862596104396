import axios from "axios";
import { API_SUPPORT, API_SUPPORT_ID } from "Constants/API/Support";
import { HttpHeadersAuthorization } from "Helpers/common";
import { TSupportStatus } from "Types/common";
import { ISuccessRes } from "Types/responce";
import { ISupport, ISupportItem } from "Types/support";

export const SupportServices = {
  async getSupports(status?: TSupportStatus) {
    return axios
      .get<ISuccessRes<ISupportItem[]>>(API_SUPPORT, {
        headers: HttpHeadersAuthorization(),
        params: { status },
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((e) => {
        throw e;
      });
  },
  async getSupport(id: string) {
    return axios
      .get<ISuccessRes<ISupport>>(API_SUPPORT_ID(id), {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((e) => {
        throw e;
      });
  },
  async updateStatus(id: string, status: TSupportStatus) {
    return axios.post(
      API_SUPPORT_ID(id),
      { status },
      { headers: HttpHeadersAuthorization() }
    );
  },
};
