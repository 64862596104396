import { FC } from "react";
import { FormGroup, InputGroup, TextArea } from "@blueprintjs/core";

interface LinkContentModalProps {
  data: { label: string; url: string; description: string; dataTitle: string };
  isDisabled: boolean;
  error: any;
  onChange: (content: any) => void;
}

export const LinkContentModal: FC<LinkContentModalProps> = ({
  data,
  isDisabled,
  error,
  onChange,
}) => {
  const onChangeText: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange({ ...data, [e.target.id]: e.target.value });
  };

  return (
    <div>
      <FormGroup label={"Title"} {...error("dataTitle")} labelFor={"dataTitle"}>
        <InputGroup
          disabled={isDisabled}
          value={data?.dataTitle || ""}
          onChange={onChangeText}
          id={"dataTitle"}
          {...error("dataTitle")}
        />
      </FormGroup>
      <FormGroup label={"Label"} {...error("label")} labelFor={"label"}>
        <InputGroup
          disabled={isDisabled}
          value={data?.label || ""}
          onChange={onChangeText}
          id={"label"}
          {...error("label")}
        />
      </FormGroup>
      <FormGroup label={"URL"} {...error("url")} labelFor={"url"}>
        <InputGroup
          disabled={isDisabled}
          value={data?.url || ""}
          onChange={onChangeText}
          id={"url"}
          {...error("url")}
        />
      </FormGroup>
      <FormGroup
        label={"Description"}
        {...error("description")}
        labelFor={"description"}
      >
        <TextArea
          disabled={isDisabled}
          value={data?.description || ""}
          onChange={onChangeText}
          id={"description"}
          fill
          {...error("description")}
        />
      </FormGroup>
    </div>
  );
};
