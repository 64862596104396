import { IAddCourse } from "Componens/courses/modalMainCourse/constants";
import { URL_COURSES_ID } from "Constants/URL";
import { CoursesServices } from "Helpers/api/Courses";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { IValidError } from "Types/common";

export const useCourseList = () => {
  const { data, isError, isLoading } = useQuery(
    ["courses", "list"],
    CoursesServices.getCourseList
  );

  return { data, isError, isLoading };
};

export const useCourse = (id: string) => {
  const { data, isError, isLoading } = useQuery(
    ["courses", id],
    () => CoursesServices.getCourse(id),
    {
      enabled: !!id,
    }
  );

  return { data, isError, isLoading };
};

export const useAddCourse = (onCloseModal: () => void, isNew: boolean) => {
  const [errors, setErrors] = useState<IValidError[]>([]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, isLoading: isDisabled } = useMutation(
    (data: IAddCourse) => {
      if (isNew) {
        return CoursesServices.addCurse(data);
      } else {
        return CoursesServices.updateCourse(data);
      }
    },
    {
      onMutate: () => {
        setErrors([]);
      },
      onSuccess: (id) => {
        if (isNew) {
          navigate(URL_COURSES_ID(id as string));
        } else {
          queryClient.refetchQueries(["courses", id]);
        }
        queryClient.refetchQueries(["courses", "list"]);

        onCloseModal();
      },
      onError: (errorsRes: IValidError[]) => {
        if (Array.isArray(errorsRes)) {
          setErrors(errorsRes);
        }
      },
    }
  );

  return { mutate, isDisabled, errors };
};
