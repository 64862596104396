import { IEditPrice } from "Componens/courses/coursecBlocks/pricesInfo";
import { INFINITY, INFINITY_DAYS } from "Constants/App";
import { PricesServices } from "Helpers/api/Courses/Prices";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { IValidError } from "Types/common";

interface IVariables {
  data: IEditPrice;
  courseId: string;
}

export const useAddEditPriceCource = (closeModal: () => void) => {
  const queryClient = useQueryClient();

  const [errors, setErrors] = useState<IValidError[]>([]);
  const { isLoading, mutate } = useMutation(
    ({ data, courseId }: IVariables) => {
      if (data.id) {
        return PricesServices.editPrice(courseId, {
          ...data,
          days: data.days === INFINITY ? INFINITY_DAYS : data.days,
        });
      }
      return PricesServices.addPrice(courseId, {
        ...data,
        days: data.days === INFINITY ? INFINITY_DAYS : data.days,
      });
    },
    {
      onMutate: () => {
        setErrors([]);
      },
      onSuccess: (id) => {
        queryClient.refetchQueries(["courses", id]);
        closeModal();
      },
      onError: (errorsRes: IValidError[]) => {
        if (Array.isArray(errorsRes)) {
          setErrors(errorsRes);
        }
      },
    }
  );

  return { errors, isDisabled: isLoading, mutate, setErrors };
};
