import { kinescopeServices } from "Helpers/api/Courses/kinescope";
import { useQuery } from "react-query";

export const useKinescopeVideos = () => {
  const { data, isLoading } = useQuery(
    ["course", "kinescope", "videos"],
    kinescopeServices.getAllVideos
  );

  return { data, isLoading };
};
