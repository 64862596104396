import { ICourseModuleEdit } from "Componens/courses/coursecBlocks/modules/EditModules/types";
import { modulesServices } from "Helpers/api/Courses/modules";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { IValidError } from "Types/common";
import { ICourseModule } from "Types/courses";

interface IVariables {
  courseId: string;
  data: { modules: (ICourseModuleEdit | ICourseModule)[]; id: string };
}

export const useUpdateModules = (closeModal: (a: any) => void) => {
  const queryClient = useQueryClient();

  const [errors, setErrors] = useState<IValidError[]>([]);
  const { isLoading, mutate } = useMutation(
    ({ data, courseId }: IVariables) => {
      return modulesServices.updateModules(courseId, data);
    },
    {
      onMutate: () => {
        setErrors([]);
      },
      onSuccess: ({ id, mainModuleId }) => {
        queryClient.refetchQueries(["courses", id, "module", mainModuleId]);
        closeModal(undefined);
      },
      onError: (errorsRes: IValidError[]) => {
        if (Array.isArray(errorsRes)) {
          setErrors(errorsRes);
        }
      },
    }
  );

  return { errors, isDisabled: isLoading, mutate, setErrors };
};

interface IVariablesDel {
  courseId: string;
  moduleId: number;
  mainModuleId: string;
}

export const useDeleteModule = (closeModal: any) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    ({ moduleId, mainModuleId, courseId }: IVariablesDel) => {
      return modulesServices.deleteModule(courseId, moduleId, mainModuleId);
    },
    {
      onSuccess: ({ id, mainModuleId }) => {
        queryClient.refetchQueries(["courses", id, "module", mainModuleId]);
        closeModal(undefined);
      },
      onError: (errorsRes: IValidError[]) => {},
    }
  );

  return { isDisabled: isLoading, mutate };
};
