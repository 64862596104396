import { Button } from "@blueprintjs/core";
import { Status } from "Componens/common";
import { URL_COURSES_ID } from "Constants/URL";
import { FC } from "react";
import { Link } from "react-router-dom";
import { ICourseMain } from "Types/courses";

export const CoursesItem: FC<ICourseMain> = ({
  id,
  regionOnly,
  status,
  title,
}) => {
  return (
    <tr>
      <td>
        <Link to={URL_COURSES_ID(id)}>{id}</Link>
      </td>
      <td>{title}</td>
      <td>{regionOnly ? "Yes" : "No"}</td>
      <td>
        <Status status={status} />
      </td>
      <td>
        <Link to={URL_COURSES_ID(id)}>
          <Button icon={"arrow-right"} intent={"none"} minimal />
        </Link>
      </td>
    </tr>
  );
};
