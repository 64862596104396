import { Button, Card, Collapse, Icon } from "@blueprintjs/core";
import classNames from "classnames";
import { Status } from "Componens/common";
import { TOKEN } from "Constants/App";
import { FC, useMemo, useState } from "react";
import KinescopePlayer from "@kinescope/react-kinescope-player";

import {
  BigPlayButton,
  ControlBar,
  LoadingSpinner,
  PlaybackRateMenuButton,
  Player,
  // @ts-ignore:disable-next-line
} from "video-react";

import { ICourseContent } from "Types/courses";

import { IEditContent } from "../types";
import { TContentType } from "Types/common";

import styles from "../index.module.css";

const icons: { [key: TContentType | string]: string } = {
  file: "folder-open",
  text: "document",
  video: "video",
  image: "media",
  "video-kinescope": "video",
  "stream-kinescope": "video",
  "stream-faceCast": "video",
};

interface ICourseContentProps extends ICourseContent {
  setEditData: React.Dispatch<React.SetStateAction<IEditContent | undefined>>;
}

export const ItemContent: FC<ICourseContentProps> = ({
  title,
  type,
  data,
  status,
  courseModuleId,
  id,
  setEditData,
  fileId,
}) => {
  const [openCollapse, setOpenCollapse] = useState(false);

  const onClickOpenCollapse = () => {
    setOpenCollapse((v) => !v);
  };

  const urlContent = useMemo(
    () =>
      process.env.REACT_APP_API_URL +
      "/admin" +
      data +
      "?token=" +
      localStorage.getItem(TOKEN),
    [data]
  );

  const onClickEditContent = () => {
    setEditData({ id, title, type, data, courseModuleId, status, fileId });
  };

  return (
    <Card>
      <div className="row">
        <div onClick={onClickOpenCollapse} className={styles["collapse"]}>
          <Icon icon={icons[type] as any} /> {title} ({type})
          <Icon
            icon={"chevron-down"}
            intent={"primary"}
            className={classNames(styles["chevron"], {
              [styles["chevron-active"]]: openCollapse,
            })}
          />
        </div>
        <div>
          <Status status={status} />
          <Button icon={"edit"} minimal onClick={onClickEditContent} />
        </div>
      </div>
      <Collapse isOpen={openCollapse}>
        <div>
          {type === "text" && (
            <div dangerouslySetInnerHTML={{ __html: data }}></div>
          )}
          {type === "image" && <img src={urlContent} alt="" />}
          {type === "file" && <div>{urlContent}</div>}
          {type === "video" && (
            <div style={{ width: "55%" }}>
              <Player src={urlContent} playsInline>
                <BigPlayButton position="center" />
                <LoadingSpinner />
                <ControlBar>
                  <PlaybackRateMenuButton rates={[2, 1, 0.5]} />
                </ControlBar>
              </Player>
            </div>
          )}
          {type === "video-kinescope" && (
            <div style={{ height: 400, width: "55%" }}>
              <KinescopePlayer videoId={data} />
            </div>
          )}
          {type === "link" && (
            <div style={{ width: "55%" }}>
              <div>Title: {data?.dataTitle}</div>
              <div>Label URL: {data?.label}</div>
              <div>URL: {data?.url}</div>
              <div>Description: {data?.description}</div>
            </div>
          )}
          {type === "announce" && (
            <div style={{ width: "55%" }}>
              <div>Title: {data?.dataTitle}</div>
              <div>Description: {data?.description}</div>
            </div>
          )}
        </div>
      </Collapse>
    </Card>
  );
};
