import { Card, HTMLSelect, HTMLTable, Icon } from "@blueprintjs/core";
import { Loading } from "Componens/common";
import { SupportItem } from "Componens/support/supportItem";
import { useSupports } from "Hooks/api/support";
import { FC, useState } from "react";
import { Outlet } from "react-router-dom";

import styles from "Styles/pageStyles/list.module.css";
import { TSupportStatus } from "Types/common";

export const SupportPage: FC = () => {
  const [status, setStatus] = useState<TSupportStatus>("new");
  const { data, isLoading } = useSupports(status);

  const onChangeStatus: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setStatus(e.target.value as TSupportStatus);
  };

  return (
    <div>
      <Card>
        <div className={styles["row"]}>
          <h1>Supports</h1>
          <div>
            <Icon icon={"filter"} style={{ marginRight: 7 }} />
            <HTMLSelect value={status} onChange={onChangeStatus}>
              <option value={"new"}>New</option>
              <option value={"work"}>Work</option>
              <option value={"closed"}>Closed</option>
              <option value={"spam"}>Spam</option>
            </HTMLSelect>
          </div>
        </div>
        <div style={{ minHeight: 550 }}>
          <HTMLTable style={{ width: "100%" }}>
            <thead>
              <tr>
                <td>Id</td>
                <td>Name</td>
                <td>Create At</td>
                <td>Status</td>
                <td>Options</td>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <Loading className={styles["loading"]} />
              ) : (
                data?.map((item) => (
                  <SupportItem {...item} key={"support" + item.id} />
                ))
              )}
            </tbody>
          </HTMLTable>
        </div>
      </Card>
      <Outlet />
    </div>
  );
};
