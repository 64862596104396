import { FC, useMemo } from "react";

import c from "classnames";
import { ICourseMainInfo } from "Types/courses";

import { Status } from "Componens/common";
import { useAppSelector } from "Hooks/redux";
import { getUrlImg } from "Helpers/common";
import { Button, Tag } from "@blueprintjs/core";
import { IEditDataMainInfo } from "..";

import styles from "./index.module.css";

interface ICourseMainInfoItem extends ICourseMainInfo {
  setEditData: (data: IEditDataMainInfo) => void;
}

export const CourseMainInfoItem: FC<ICourseMainInfoItem> = ({
  img,
  id,
  title,
  description,
  status,
  regionId,
  setEditData,
}) => {
  const regions = useAppSelector((s) => s.App.regions);

  const regionName = useMemo(() => {
    return regions.find((item) => item.id === regionId)?.title || "";
  }, [regionId, regions]);

  const onClickEdit = () => {
    if (id) {
      setEditData({ id, img, title, regionId, description, status });
    }
  };

  return (
    <div>
      <div className={styles["adminRow"]}>
        <div className={styles["adminInfo"]}>
          <span className={styles["adminInfoStatus"]}>
            Status: <Status status={status} />
          </span>
          Lang:{" "}
          <Tag intent="primary" minimal large>
            {regionName}
          </Tag>
        </div>
        <Button icon={"edit"} minimal onClick={onClickEdit} />
      </div>
      <div className={c(styles["item_wrapper"], "anim_opacity")}>
        <div className={styles["item"]}>
          <div className={styles["item_img"]}>
            <img src={getUrlImg(img)} alt="cource" />
          </div>
          <div className={styles["item_subTitle"]}>Video</div>
          <div className={styles["item_title"]}>{title}</div>
          <div className={styles["item_descr"]}>{description}</div>
        </div>
      </div>
    </div>
  );
};
