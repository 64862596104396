import { Button, Card, Tab, Tabs } from "@blueprintjs/core";
import { Loading, Status } from "Componens/common";
import {
  FilesBlock,
  MainInfos,
  PricesInfo,
} from "Componens/courses/coursecBlocks";
import { Announce } from "Componens/courses/coursecBlocks/announce";
import { ModulesCourse } from "Componens/courses/coursecBlocks/modules";
import { ModalMainCourse } from "Componens/courses/modalMainCourse";
import { IAddCourse } from "Componens/courses/modalMainCourse/constants";
import {
  URL_COURSES_$ID_ANNOUNCE,
  URL_COURSES_$ID_FILES,
  URL_COURSES_$ID_MAIN_INFO,
  URL_COURSES_$ID_MODULES,
  URL_COURSES_$ID_PRICES,
  URL_COURSES_ID,
} from "Constants/URL";
import { useCourse } from "Hooks/api/courses";
import { useAppDispatch, useAppSelector } from "Hooks/redux";
import { createContext, FC, useEffect, useMemo, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import styles from "Styles/pageStyles/admins/courceItem.module.css";
import { IFile } from "Types/courses";

export const FileContext = createContext<IFile[]>([]);

export const CourseItemPage: FC = () => {
  const { id } = useParams<string>();
  const regions = useAppSelector((state) => state.App.regions);

  const { data, isLoading } = useCourse(id as string);

  const [selectedRegion, setSelectedRegion] = useState(1);
  const onSelectedRegion = (id: number) => {
    setSelectedRegion(id);
  };

  const { getRegions } = useAppDispatch();

  useEffect(() => {
    getRegions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { pathname } = useLocation();

  const [tab, setTab] = useState(
    pathname.includes("modules")
      ? "/modules"
      : pathname.includes("prices")
      ? "/prices"
      : pathname.includes("files")
      ? "/files"
      : "/"
  );

  const navigate = useNavigate();
  const onSelectTab = (tabActive: string) => {
    setTab(tabActive);
    navigate(URL_COURSES_ID(id as string) + tabActive);
  };

  const isDisabledRegions = useMemo(() => {
    return pathname.includes("/modules/");
  }, [pathname]);

  const [editData, setEditData] = useState<undefined | IAddCourse>(undefined);

  const onOpenModal = () => {
    if (!data || !id) return;
    setEditData({
      id: +id,
      title: data?.title,
      regionOnly: data.regionOnly,
      status: data.status,
      type: data.type,
      isAnnouncement: data.isAnnouncement,
    });
  };

  if (isLoading) {
    return <Loading fullScreen />;
  }

  // if(isError) {}

  return (
    <Card>
      <ModalMainCourse
        isOpen={!!editData}
        data={editData}
        setData={setEditData}
      />
      <Card>
        <div className={styles["row"]}>
          <div>
            ID #{id} {data?.title}
          </div>
          <div>Status: {data && <Status status={data.status} />}</div>
          <div>Region only: {data?.regionOnly ? "Yes" : "No"}</div>
          <div>Type: {data?.type}</div>
          <div>Announce: {data?.isAnnouncement ? "Yes" : "No"}</div>
          <div>
            <Button icon={"edit"} minimal onClick={onOpenModal} />
          </div>
        </div>
        <div>
          <h2>Tabs:</h2>
          <Tabs id="tabsPage" onChange={onSelectTab} selectedTabId={tab}>
            <Tab id={"/"} title={"Main Info"} />
            <Tab id={"/announce"} title={"Announce"} />
            <Tab id={"/modules"} title={"Modules"} />
            <Tab id={"/prices"} title={"Prices"} />
            <Tab id={"/files"} title={"Files"} />
          </Tabs>
        </div>
      </Card>
      <div className={styles["container"]}>
        {!pathname.includes("file") && (
          <div className={styles["regions"]}>
            <h2>Regions</h2>
            <Tabs
              id="tabsRegions"
              onChange={onSelectedRegion}
              selectedTabId={selectedRegion}
            >
              {regions
                .filter((item) => item.status === "active")
                .map((item, index) => (
                  <Tab
                    id={item.id}
                    title={item.title}
                    key={item.lang + index}
                    disabled={isDisabledRegions}
                  />
                ))}
            </Tabs>
          </div>
        )}
        <Routes>
          <Route
            path={URL_COURSES_$ID_MAIN_INFO}
            element={
              <MainInfos
                selectedRegion={selectedRegion}
                courseMainInfos={data?.courseMainInfos || []}
              />
            }
          />
          <Route
            path={URL_COURSES_$ID_ANNOUNCE}
            element={
              <Announce
                selectedRegion={selectedRegion}
                courseAnnouncements={data?.courseAnnouncements || []}
              />
            }
          />
          <Route
            path={URL_COURSES_$ID_MODULES}
            element={
              <FileContext.Provider value={data?.files || []}>
                <ModulesCourse
                  courceModulesMains={data?.courceModulesMains || []}
                  selectedRegion={selectedRegion}
                />
              </FileContext.Provider>
            }
          />
          <Route
            path={URL_COURSES_$ID_PRICES}
            element={
              <PricesInfo
                selectedRegion={selectedRegion}
                coursePrices={data?.coursePrices || []}
              />
            }
          />
          <Route
            path={URL_COURSES_$ID_FILES}
            element={<FilesBlock data={data?.files || []} />}
          />
        </Routes>
      </div>
    </Card>
  );
};
