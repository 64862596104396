import { IEditDataMainModules } from "Componens/courses/coursecBlocks/modules/Modal";
import { mainModulesServices } from "Helpers/api/Courses/modules";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { IValidError } from "Types/common";

interface IVariables {
  data: IEditDataMainModules;
  courseId: string;
}

export const useAddEditMainModules = (closeModal: () => void) => {
  const queryClient = useQueryClient();

  const [errors, setErrors] = useState<IValidError[]>([]);
  const { isLoading, mutate } = useMutation(
    ({ data, courseId }: IVariables) => {
      if (data.id) {
        return mainModulesServices.updateModule(courseId, data);
      }
      return mainModulesServices.createModule(courseId, data);
    },
    {
      onMutate: () => {
        setErrors([]);
      },
      onSuccess: (id) => {
        queryClient.refetchQueries(["courses", id]);
        closeModal();
      },
      onError: (errorsRes: IValidError[]) => {
        if (Array.isArray(errorsRes)) {
          setErrors(errorsRes);
        }
      },
    }
  );

  return { errors, isDisabled: isLoading, mutate, setErrors };
};

export const useGetMainModule = (id: string, courseId: string) => {
  const { isError, isLoading, data } = useQuery(
    ["courses", courseId, "module", id],
    () => mainModulesServices.getMainModule(id, courseId),
    {
      enabled: !!id && !!courseId,
      refetchOnWindowFocus: false,
    }
  );

  return { data, isError, isLoading };
};
