import { FC } from "react";

import { Icon } from "../Icon";

import styles from "./index.module.css";

interface ILoadingProps {
  fullScreen?: boolean;
  className?: string;
}

export const Loading: FC<ILoadingProps> = ({ fullScreen, className }) => {
  if (fullScreen) {
    return (
      <div className={styles["loader__wraper"]}>
        <div className="anim_opacity">
          <Icon icon={"logo"} className={styles["loader"]} />
        </div>
      </div>
    );
  }

  return (
    <div className={"anim_opacity " + className}>
      <Icon icon={"logo"} className={styles["loader"]} />
    </div>
  );
};
