import { TOKEN } from "Constants/App";
import { ProfileService } from "Helpers/api/Profile";
import { RegionsServices } from "Helpers/api/Regions";
import { Dispatch } from "redux";
import { AppActions, AppActionsTypes } from "./types";

export const checkAuth = () => async (dispatch: Dispatch<AppActions> | any) => {
  dispatch({ type: AppActionsTypes.APP_LOADING_APP });
  try {
    if (!localStorage.getItem(TOKEN)) {
      dispatch({ type: AppActionsTypes.APP_ERROR_AUTH });
    }
    const authData = await ProfileService.getProfileInfo();

    if (!authData) {
      throw new Error("not authData");
    }

    if (authData.jwt) {
      localStorage.setItem(TOKEN, authData.jwt);
    }

    dispatch({
      type: AppActionsTypes.APP_SUCCESS_AUTH,
      payload: authData.admin,
    });

    dispatch(getRegions());
  } catch {
    dispatch({ type: AppActionsTypes.APP_ERROR_AUTH });
    localStorage.removeItem(TOKEN);
  }
};

export const getRegions = () => async (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: AppActionsTypes.APP_FETCH_REGIONS });

  const regions = await RegionsServices.getAllregions();

  if (regions) {
    dispatch({ type: AppActionsTypes.APP_FILL_REGIONS, payload: regions });
  }
};
