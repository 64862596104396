import { TOKEN } from "Constants/App";
import moment from "moment";

export function HttpHeadersAuthorization() {
  const tokenLocalStorage = localStorage.getItem(TOKEN);

  if (tokenLocalStorage) {
    return { Authorization: `Bearer ${tokenLocalStorage}` };
  }

  const tokenSessionStorage = sessionStorage.getItem(TOKEN);

  if (tokenSessionStorage) {
    return { Authorization: `Bearer ${tokenSessionStorage}` };
  }
}

export function getUrlImg(url: string) {
  if (url.includes("http")) {
    return url;
  } else {
    return process.env.REACT_APP_API_URL + url;
  }
}

export function DateToFormat(date: string) {
  return moment(date).format("DD.MM.YYYY HH:mm");
}
