import { kinescopeServices } from "Helpers/api/Courses/kinescope";
import { useQuery } from "react-query";

export const useKinescopeStreams = () => {
  const { data, isLoading } = useQuery({
    queryFn: kinescopeServices.getAllStreams,
    queryKey: ["course", "kinescope", "streams"],
  });

  return { data, isLoading };
};
