import { Button, Callout, Card } from "@blueprintjs/core";
import { useDeleteFile } from "Hooks/api/courses/files";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { IFile } from "Types/courses";

import styles from "./index.module.css";
import { ModalFile } from "./modalFile";

interface IFileBlock {
  data: IFile[];
}

export interface IFileEdit extends IFile {
  data?: File;
}

export const FilesBlock: FC<IFileBlock> = ({ data }) => {
  const [editData, setEditData] = useState<IFileEdit | undefined>();

  const onClickOpenModal = () => {
    setEditData({ title: "", type: "file", path: "" });
  };

  return (
    <div className={styles["block"]}>
      <h2>
        Files <Button icon={"add"} minimal onClick={onClickOpenModal} />
      </h2>
      <div className={styles["grid"]}>
        {data.map((item, index) => (
          <FileItem {...item} key={index + "file"} setEditData={setEditData} />
        ))}
      </div>
      <ModalFile data={editData} setEditData={setEditData} />
    </div>
  );
};

interface IFileItem extends IFile {
  setEditData: React.Dispatch<React.SetStateAction<IFileEdit | undefined>>;
}

const FileItem: FC<IFileItem> = ({ title, path, type, setEditData, id }) => {
  const { id: courseId } = useParams<string>();

  const { errors, mutate, isDisabled } = useDeleteFile();

  const onClickDeleteFile = () => {
    if (!courseId || !id) return;
    mutate({ courseId, fileId: id });
  };

  return (
    <Card className={styles["card"]}>
      <div className={styles["content"]}>
        {type === "image" && (
          <img alt="" src="http://localhost:5000/public/course/1/gwk49w.webp" />
        )}
        {type === "file" && <div>{path}</div>}
        {type === "video" && <div>{path}</div>}
      </div>
      <div className={styles["row"]}>
        <div className={styles["title"]}>{title}</div>
        <div>
          <Button
            icon={"trash"}
            onClick={onClickDeleteFile}
            minimal
            disabled={isDisabled}
          />
        </div>
      </div>
      {errors.length > 0 && (
        <Callout intent="danger" title="Errors">
          {errors.map((error, index) => (
            <div key={"errror" + index}>{error.message}</div>
          ))}
        </Callout>
      )}
    </Card>
  );
};
