const host = process.env.REACT_APP_API_URL + "/admin";

export const API_COURSES = host + "/cource";
export const API_COURSES_ID = (id: string | number) => API_COURSES + "/" + id;

//Prices
export const API_PRICES = (id: string | number) =>
  API_COURSES_ID(id) + "/price";

// Main Info
export const API_MAIN_INFO = (id: string | number) =>
  API_COURSES_ID(id) + "/main";

// Announce
export const API_ANNOUNCE = (id: string | number) =>
  API_COURSES_ID(id) + "/announce";

// Main Modules
export const API_MAIN_MODULES = (id: string | number) =>
  API_COURSES_ID(id) + "/module-main";
export const API_MAIN_MODULES_ID = (id: string, courseId: string) =>
  API_MAIN_MODULES(courseId) + "/" + id;

export const API_MODULES = (id: string | number) =>
  API_COURSES_ID(id) + "/modules";

// Content
export const API_CONTENT_ID = (id: string | number) =>
  API_COURSES_ID(id) + "/content";

// Files
export const API_FILES_ID = (id: string | number) =>
  API_COURSES_ID(id) + "/files";

export const API_KINESCOPE_VIDEOS = API_COURSES + "/kinescope/videos";
export const API_KINESCOPE_STREAMS = API_COURSES + "/kinescope/streams";
