import { AnnounceServices } from "Helpers/api/Courses/announce";

import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { IValidError } from "Types/common";

interface IVariables {
  data: any;
  courseId: string;
}

export const useAddEditAnnounce = (closeModal: () => void) => {
  const queryClient = useQueryClient();

  const [errors, setErrors] = useState<IValidError[]>([]);
  const { isLoading, mutate } = useMutation(
    ({ data, courseId }: IVariables) => {
      if (data.id) {
        return AnnounceServices.editAnnounce(courseId, data);
      }
      return AnnounceServices.addAnnounce(courseId, data);
    },
    {
      onMutate: () => {
        setErrors([]);
      },
      onSuccess: (id) => {
        queryClient.refetchQueries(["courses", id]);
        closeModal();
      },
      onError: (errorsRes: IValidError[]) => {
        if (Array.isArray(errorsRes)) {
          setErrors(errorsRes);
        }
      },
    }
  );

  return { errors, isDisabled: isLoading, mutate, setErrors };
};

interface IVariablesDel {
  courseId: string;
  id: number;
}

export const useDeleteAnnounce = (closeModal: any) => {
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(
    ({ id, courseId }: IVariablesDel) => {
      return AnnounceServices.deleteAnnounce(courseId, id);
    },
    {
      onSuccess: (id) => {
        queryClient.refetchQueries(["courses", id]);
        closeModal();
      },
    }
  );

  return { isDisabled: isLoading, mutate };
};
