import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  HTMLTable,
  InputGroup,
} from "@blueprintjs/core";
import { useError } from "Hooks/common";
import { useAppSelector } from "Hooks/redux";
import { FC, useState } from "react";
import { IRegion } from "Types/common";
// import Creatable from "react-select/creatable";
// import { MultiValue } from "react-select";
import { useAddEditRegions } from "Hooks/api/regions";
// import OptionsCountry from "Data/country.json";
import OptionsLangInterface from "Data/interfaceLang.json";
import { Status } from "Componens/common";

export const RegionsSettings: FC = () => {
  const regions = useAppSelector((s) => s.App.regions);

  const [editData, setEditData] = useState<undefined | IRegion>();

  const onClickOpenModal = (data?: IRegion) => {
    if (data) {
      setEditData(data);
    } else {
      setEditData({
        title: "",
        regions: [],
        status: "active",
        lang: OptionsLangInterface[0].value,
      });
    }
  };

  const onClickCloseModal = () => {
    setEditData(undefined);
    setErrors([]);
  };

  const onSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (editData) {
      setEditData({
        ...editData,
        [e.target.id]: e.target.value,
        title:
          e.target.id === "lang" && e.target.value === "default"
            ? "Default"
            : editData.title,
      });
    }
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (editData) {
      setEditData({ ...editData, [e.target.id]: e.target.value });
    }
  };

  // const onSelectMuilti = (
  //   tags: MultiValue<{ value: string; label: string }>
  // ) => {
  //   if (editData) {
  //     setEditData({
  //       ...editData,
  //       regions: tags.map((item) => item.value.toLocaleUpperCase()),
  //     });
  //   }
  // };

  // const multiValue = useMemo(() => {
  //   if (!editData) {
  //     return [];
  //   }
  //   return editData.regions.map((item) => {
  //     const itemOptions = OptionsCountry.find(
  //       (itemCountry) => itemCountry.value === item
  //     );
  //     if (itemOptions) {
  //       return itemOptions;
  //     }
  //     return { value: item, label: item };
  //   });
  // }, [editData]);

  const { errors, setErrors, isDisabled, mutate } =
    useAddEditRegions(onClickCloseModal);

  const error = useError(errors);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (editData) {
      mutate(editData);
    }
  };

  return (
    <div className="anim_opacity">
      <h2>
        Regions{" "}
        <Button icon={"add"} minimal onClick={() => onClickOpenModal()} />
      </h2>
      <div>
        <HTMLTable width={"100%"}>
          <thead>
            <tr>
              <td>ID</td>
              <td>Title</td>
              <td>Lang (interface)</td>
              {/* <td>Regions</td> */}
              <td>Status</td>
              <td>Options</td>
            </tr>
          </thead>
          <tbody>
            {regions.map((item, index) => (
              <RegionsItem
                {...item}
                key={index + "region"}
                onClickEdit={onClickOpenModal}
              />
            ))}
          </tbody>
        </HTMLTable>
      </div>
      <Dialog
        isOpen={!!editData}
        title={editData?.id ? "Edit Region" : "Add Region"}
        onClose={onClickCloseModal}
      >
        <form onSubmit={onSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label={"Title"} {...error("title")} labelFor={"title"}>
              <InputGroup
                disabled={isDisabled}
                value={editData?.title || ""}
                onChange={onChange}
                id={"title"}
                {...error("title")}
              />
            </FormGroup>
            <FormGroup
              label={"Lang"}
              labelInfo={"(Interface)"}
              {...error("lang")}
              labelFor={"lang"}
            >
              <HTMLSelect
                disabled={isDisabled}
                value={editData?.lang || ""}
                onChange={onSelect}
                id={"lang"}
                {...error("lang")}
                fill
              >
                {OptionsLangInterface.map(({ value, label }, index) => (
                  <option value={value} key={"option" + index + value}>
                    {label}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>
            {/* <FormGroup
              labelFor={"regions"}
              {...error("regions")}
              label={"Regions"}
            >
              <Creatable
                isMulti
                id="regions"
                value={multiValue}
                options={OptionsCountry}
                onChange={onSelectMuilti}
                isDisabled={isDisabled || editData?.lang === "default"}
              />
            </FormGroup> */}
            {editData?.id !== 1 && (
              <FormGroup
                label={"Status"}
                {...error("status")}
                labelFor={"status"}
              >
                <HTMLSelect
                  id="status"
                  {...error("status")}
                  fill
                  value={editData?.status}
                  onChange={onSelect}
                  disabled={isDisabled}
                >
                  <option value={"active"}>Active</option>
                  <option value={"disabled"}>Disabled</option>
                </HTMLSelect>
              </FormGroup>
            )}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                onClick={onClickCloseModal}
                intent={"danger"}
                type={"button"}
                disabled={isDisabled}
              >
                Close
              </Button>

              <Button icon={"saved"} type={"submit"} disabled={isDisabled}>
                Save
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

interface IRegionsItem extends IRegion {
  onClickEdit: (data?: IRegion | undefined) => void;
}

const RegionsItem: FC<IRegionsItem> = ({
  id,
  title,
  regions,
  status,
  lang,
  onClickEdit,
}) => {
  return (
    <tr>
      <td>{id}</td>
      <td>{title}</td>
      <td>{lang}</td>
      {/* <td>{regions.length === 0 ? "All" : regions.join(", ")}</td> */}
      <td>
        <Status
          statusCustom={status}
          TagNamesCustom={{ active: "Active", disabled: "Disabled" }}
          TagsIntentCustom={{ active: "success", disabled: "danger" }}
        />
      </td>
      <td>
        <Button
          icon={"edit"}
          minimal
          onClick={() => onClickEdit({ id, title, lang, regions, status })}
        />
      </td>
    </tr>
  );
};
