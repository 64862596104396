import axios, { AxiosError } from "axios";
import { API_REGIONS } from "Constants/API/Regions";
import { HttpHeadersAuthorization } from "Helpers/common";
import { IRegion } from "Types/common";
import { ISuccessRes, IValidErrorRes } from "Types/responce";

export const RegionsServices = {
  async getAllregions() {
    return axios
      .get<ISuccessRes<IRegion[]>>(API_REGIONS, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => res.data.data)
      .catch((e) => {
        throw e;
      });
  },
  async createRegion(data: IRegion) {
    return axios
      .post(API_REGIONS, data, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => res.data.data)
      .catch((e: AxiosError<IValidErrorRes>) => {
        throw e.response?.data.errors;
      });
  },
  async editRegion(data: IRegion) {
    return axios
      .put(API_REGIONS, data, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => res.data.data)
      .catch((e: AxiosError<IValidErrorRes>) => {
        throw e.response?.data.errors;
      });
  },
};
