import { FC, useEffect, useMemo } from "react";
import { KinescopeSelectStreamsProps } from "./types";
import { HTMLSelect, InputGroup } from "@blueprintjs/core";
import { useKinescopeStreams } from "Hooks/api/courses/kinescope/useKinescopeStreams";

export const KinescopeSelectStreams: FC<KinescopeSelectStreamsProps> = ({
  isDisabled,
  onChangeData,
  data,
  ...props
}) => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChangeData(e.target.value);
  };

  const onSelectVideol: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    onChangeData(e.target.value);
  };

  const { data: allVideos, isLoading } = useKinescopeStreams();

  const videoOptions = useMemo(() => {
    return allVideos?.map((i: any) => ({ id: i.id, title: i.name })) || [];
  }, [allVideos]);

  useEffect(() => {
    try {
      if (JSON.parse(data)?.id) {
        onChangeData(JSON.parse(data).id);
      }
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <HTMLSelect
        disabled={isDisabled || isLoading}
        fill
        style={{ marginBottom: 15 }}
        value={typeof data === "string" ? data : (data as any)?.id}
        onChange={onSelectVideol}
      >
        <option value={""}>Choose...</option>
        {videoOptions.map((i: any) => (
          <option value={i.id}>{i.title}</option>
        ))}
      </HTMLSelect>
      <InputGroup
        disabled={isDisabled}
        value={typeof data === "string" ? data : (data as any)?.id}
        onChange={onChange}
        id={"data"}
        {...props}
      />
    </div>
  );
};
