import { constentServices } from "Helpers/api/Courses/content";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { IValidError } from "Types/common";

interface IVariables {
  data: any;
  courseId: string;
  mainModuleId: number;
  isNew: boolean;
}

export const useContentSave = (closeModal: any) => {
  const queryClient = useQueryClient();

  const [errors, setErrors] = useState<IValidError[]>([]);
  const [persent, setPersent] = useState<undefined | number>();

  const { isLoading, mutate } = useMutation(
    ({ data, courseId, mainModuleId, isNew }: IVariables) => {
      if (!isNew) {
        return constentServices.updateContent({
          courseId,
          data,
          mainModuleId,
          any: { setPersent },
        });
      }
      return constentServices.createContent({
        courseId,
        data,
        any: { setPersent },
        mainModuleId,
      });
    },
    {
      onMutate: () => {
        setErrors([]);
        setPersent(undefined);
      },
      onSuccess: async ({ courseId, mainModuleId }) => {
        queryClient.refetchQueries([
          "courses",
          courseId,
          "module",
          mainModuleId.toString(),
        ]);

        setPersent(undefined);
        closeModal();
      },
      onError: (errorsRes: IValidError[]) => {
        if (Array.isArray(errorsRes)) {
          setErrors(errorsRes);
        }
        setPersent(undefined);
      },
    }
  );

  return { errors, isDisabled: isLoading, mutate, setErrors, persent };
};

interface IVariablesDel {
  courseId: string;
  mainModuleId: number;
  contentId: number;
}

export const useDeleteContent = (closeModal: any) => {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(
    ({ courseId, mainModuleId, contentId }: IVariablesDel) =>
      constentServices.deleteContent(courseId, contentId, mainModuleId),
    {
      onSuccess: async ({ courseId, mainModuleId }) => {
        queryClient.refetchQueries([
          "courses",
          courseId,
          "module",
          mainModuleId.toString(),
        ]);

        closeModal();
      },
    }
  );

  return { isLoading, mutate };
};
