import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  HTMLTable,
  NumericInput,
  Switch,
} from "@blueprintjs/core";
import { useError } from "Hooks/common";
import { FC, useCallback, useMemo, useState } from "react";
import { TCourcePriceStatus, TPriceType } from "Types/common";
import { ICoursePrice } from "Types/courses";
import { CoursePriceItem } from "./coursePriceItem";

import CurrencyOptions from "Data/currency.json";

import styles from "./index.module.css";
import { useAppSelector } from "Hooks/redux";
import { INFINITY, INFINITY_DAYS } from "Constants/App";
import { useAddEditPriceCource } from "Hooks/api/courses/prices";
import { useParams } from "react-router-dom";

interface IPricesInfo {
  selectedRegion: number;
  coursePrices: ICoursePrice[];
}

export interface IEditPrice {
  id?: number;
  sum: number;
  currency: string;
  days: number | string;
  regionId: number;
  status: TCourcePriceStatus;
  type: TPriceType;
  maxPeriod?: number;
  inMainInfo?: boolean;
}

type TFilter = TCourcePriceStatus | "all";

export const PricesInfo: FC<IPricesInfo> = ({
  selectedRegion,
  coursePrices,
}) => {
  const [filter, setFilter] = useState<TFilter>("active");
  const onChangeFilter: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setFilter(e.target.value as TFilter);
  };
  const pricesRegion = useMemo(() => {
    let mainInfo = coursePrices
      .filter((item) => item.regionId === selectedRegion)
      .sort((a, b) => (a.status > b.status ? 1 : -1));

    // if (mainInfo.length === 0) {
    //   mainInfo = coursePrices
    //     .filter((item) => item.regionId === 1)
    //     .sort((a, b) => (a.status > b.status ? 1 : -1));
    // }

    if (filter === "all") {
      return mainInfo;
    }

    return mainInfo.filter((item) => item.status === filter);
  }, [coursePrices, selectedRegion, filter]);

  const regions = useAppSelector((s) => s.App.regions);

  const [editData, setEditData] = useState<IEditPrice | undefined>();

  const onClickOpenModalNew = useCallback(() => {
    setEditData({
      sum: 0,
      currency: CurrencyOptions[0].value,
      days: 0,
      status: "active",
      regionId: selectedRegion,
      type: "pay",
    });
  }, [selectedRegion]);

  const onClickCloseModal = () => {
    setEditData(undefined);
    setErrors([]);
  };

  const { id: courseId } = useParams<string>();
  const {
    mutate,
    errors,
    isDisabled: isDisabeldSave,
    setErrors,
  } = useAddEditPriceCource(onClickCloseModal);

  const error = useError(errors);

  const onClickSwitchInfinity = () => {
    if (editData) {
      setEditData({
        ...editData,
        days: editData.days === INFINITY ? 0 : INFINITY,
      });
    }
  };

  const onClickInMainInfo = () => {
    if (editData) {
      setEditData({ ...editData, inMainInfo: !editData.inMainInfo });
    }
  };

  const onSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (editData) {
      setEditData({ ...editData, [e.target.id]: e.target.value });
    }
  };

  const onSelectType: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (editData) {
      setEditData({
        ...editData,
        [e.target.id]: e.target.value,
        currency: "RUB",
        sum: 0,
        days: 0,
        maxPeriod: 0,
      });
    }
  };

  const onChange: (
    valueAsNumber: number,
    valueAsString: string,
    inputElement: HTMLInputElement
  ) => void = (valueAsNumber, valueAsString, inputElement) => {
    if (editData) {
      setEditData({
        ...editData,
        [inputElement.id]: valueAsNumber,
      });
    }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (editData && courseId) {
      mutate({ courseId: courseId, data: editData });
    }
  };

  const onClickOpenModalEdit = (data: IEditPrice) => {
    setEditData(data);
  };

  const isDisabled = useMemo(
    () => isDisabeldSave || !!editData?.id,
    [isDisabeldSave, editData?.id]
  );

  return (
    <div className={styles["block"]}>
      <div className="row">
        <h2 className={styles["title"]}>
          Prices <Button icon={"add"} minimal onClick={onClickOpenModalNew} />
        </h2>
        <HTMLSelect
          value={filter}
          onChange={onChangeFilter}
          iconProps={{ icon: "filter" }}
        >
          <option value={"active"}>Active</option>
          <option value={"disabled"}>Disabled</option>
          <option value={"all"}>All</option>
        </HTMLSelect>
      </div>

      <div>
        <HTMLTable width={"100%"}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>In Main Info</th>
              <th>Sum</th>
              <th>Currency</th>
              <th>Days</th>
              <th>Region</th>
              <th>Status</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {pricesRegion.map((item, index) => (
              <CoursePriceItem
                {...item}
                key={"price" + index}
                onOpenEdit={onClickOpenModalEdit}
              />
            ))}
          </tbody>
        </HTMLTable>
      </div>
      <Dialog
        isOpen={!!editData}
        onClose={onClickCloseModal}
        title={editData?.id ? `Edit price ${editData.id}` : "Add Price"}
      >
        <form onSubmit={onSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label={"Type"} {...error("type")} labelFor={"type"}>
              <HTMLSelect
                id="type"
                {...error("type")}
                fill
                value={editData?.type}
                onChange={onSelectType}
                disabled={isDisabled}
              >
                <option value={"pay"}>Pay</option>
                <option value={"recurrent"}>Recurrent</option>
              </HTMLSelect>
            </FormGroup>

            <FormGroup
              label={"Currency"}
              {...error("currency")}
              labelFor={"currency"}
            >
              <HTMLSelect
                id="currency"
                {...error("currency")}
                fill
                value={editData?.currency}
                onChange={onSelect}
                disabled={isDisabled}
              >
                {CurrencyOptions.map((item) => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>

            <FormGroup label={"Sum"} {...error("sum")} labelFor={"sum"}>
              <NumericInput
                fill
                value={editData?.sum || 0}
                id={"sum"}
                min={0}
                minorStepSize={1}
                onValueChange={onChange}
                {...error("sum")}
                disabled={isDisabled}
              />
            </FormGroup>
            {editData?.type === "recurrent" && (
              <FormGroup
                label={"Count Payment"}
                labelInfo={"(Month)"}
                {...error("maxPeriod")}
                labelFor={"maxPeriod"}
              >
                <NumericInput
                  fill
                  value={editData?.maxPeriod || ""}
                  id={"maxPeriod"}
                  min={0}
                  minorStepSize={1}
                  onValueChange={onChange}
                  {...error("maxPeriod")}
                  disabled={isDisabled}
                />
              </FormGroup>
            )}

            <FormGroup label={"Days"} {...error("days")} labelFor={"days"}>
              <NumericInput
                fill
                value={
                  editData?.days === INFINITY_DAYS
                    ? "Infinity"
                    : editData?.days || ""
                }
                id={"days"}
                min={0}
                max={INFINITY_DAYS}
                minorStepSize={1}
                disabled={
                  editData?.days === INFINITY ||
                  isDisabled ||
                  editData?.days === INFINITY_DAYS
                }
                onValueChange={onChange}
                {...error("days")}
              />
              <Switch
                disabled={isDisabled}
                label="Infinity"
                checked={
                  editData?.days === INFINITY ||
                  editData?.days === INFINITY_DAYS
                }
                id={"days"}
                onChange={onClickSwitchInfinity}
                {...error("days")}
              />
            </FormGroup>

            <FormGroup
              label={"Status"}
              {...error("status")}
              labelFor={"status"}
            >
              <HTMLSelect
                id="status"
                {...error("status")}
                fill
                value={editData?.status}
                onChange={onSelect}
                disabled={isDisabeldSave}
              >
                <option value={"active"}>Active</option>
                <option value={"disabled"}>Disabled</option>
              </HTMLSelect>
            </FormGroup>

            <FormGroup
              label={"Region"}
              {...error("regionId")}
              labelFor={"regionId"}
            >
              <HTMLSelect
                id="regionId"
                {...error("regionId")}
                fill
                value={editData?.regionId.toString()}
                onChange={onSelect}
                disabled={isDisabled}
              >
                {regions.map((item, index) => (
                  <option value={item.id} key={"langPrice" + index}>
                    {item.title}
                  </option>
                ))}
              </HTMLSelect>
            </FormGroup>
            <Switch
              disabled={isDisabeldSave}
              label="In Main Info"
              checked={editData?.inMainInfo}
              id={"inMainInfo"}
              onChange={onClickInMainInfo}
              {...error("inMainInfo")}
            />
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button icon={"saved"} type={"submit"} disabled={isDisabeldSave}>
                {editData?.id ? "Save" : "Add price"}
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
};
