import {
  Button,
  Classes,
  Dialog,
  FileInput,
  FormGroup,
  HTMLSelect,
  InputGroup,
  ProgressBar,
} from "@blueprintjs/core";
import { useFileSave } from "Hooks/api/courses/files";
import { useError } from "Hooks/common";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { IFileEdit } from ".";

interface IModalFile {
  data: IFileEdit | undefined;
  setEditData: any;
}

export const ModalFile: FC<IModalFile> = ({ data, setEditData }) => {
  const onCloseModal = () => {
    if (isDisabled) return;

    setEditData(undefined);
    setErrors([]);
  };

  const { setErrors, errors, isDisabled, persent, mutate } =
    useFileSave(onCloseModal);

  const error = useError(errors);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (data) {
      setEditData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const onSelectType: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (data) {
      setEditData({ ...data, [e.target.id]: e.target.value, data: "" });
    }
  };

  const onChangeFile: React.FormEventHandler<HTMLInputElement> = (e) => {
    if (data) {
      setEditData({ ...data, data: e.currentTarget.files?.[0] || "" });
    }
  };

  const { id: courseId } = useParams<string>();

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (!data || !courseId) return;
    formData.append("title", data.title);
    formData.append("type", data.type);

    if (data.data) {
      formData.append("file", data.data);
    }

    if (data.id) {
      formData.append("id", data.id.toString());
    }

    mutate({
      courseId,
      data: formData,
    });
  };

  return (
    <Dialog
      isOpen={!!data}
      onClose={onCloseModal}
      title={data?.id ? "Edit File" : "New File"}
    >
      <form onSubmit={onSubmit}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={"Title"} {...error("title")} labelFor={"title"}>
            <InputGroup
              disabled={isDisabled}
              value={data?.title || ""}
              onChange={onChange}
              id={"title"}
              {...error("title")}
            />
          </FormGroup>
          <FormGroup label={"Type"} {...error("type")} labelFor={"type"}>
            <HTMLSelect
              id="type"
              fill
              value={data?.type}
              onChange={onSelectType}
              disabled={isDisabled}
              {...error("type")}
            >
              <option value={"video"}>Video</option>
              <option value={"file"}>File</option>
              <option value={"image"}>Image</option>
            </HTMLSelect>
          </FormGroup>
          <FormGroup label={"Content"} {...error("data")} labelFor={"data"}>
            <FileInput
              fill
              onInputChange={onChangeFile}
              hasSelection={!!data?.path}
              text={
                typeof data?.data === "object"
                  ? data.data.name
                  : data?.path
                  ? data.path.split("/").pop()
                  : "Choose file"
              }
              {...error("data")}
              disabled={isDisabled}
            />
          </FormGroup>
          {persent && (
            <ProgressBar
              intent={"success"}
              stripes={false}
              value={persent / 100}
            />
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={"row"} style={{ paddingTop: 15 }}>
            {data?.id ? (
              <Button
                intent={"danger"}
                type={"button"}
                disabled={isDisabled}
                icon={"trash"}
              >
                Delete
              </Button>
            ) : (
              <div />
            )}
            <Button icon={"saved"} type={"submit"} disabled={isDisabled}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
