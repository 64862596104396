import { Button, Tag } from "@blueprintjs/core";
import { Status } from "Componens/common";
import { useAppSelector } from "Hooks/redux";
import { FC, useMemo } from "react";
import { ICoursePrice } from "Types/courses";
import { IEditPrice } from "..";
import { INFINITY_DAYS } from "Constants/App";

interface ICoursePriceItem extends ICoursePrice {
  onOpenEdit: (data: IEditPrice) => void;
}

export const CoursePriceItem: FC<ICoursePriceItem> = ({
  id,
  status,
  sum,
  currency,
  days,
  regionId,
  onOpenEdit,
  type,
  maxPeriod,
  inMainInfo,
}) => {
  const regions = useAppSelector((s) => s.App.regions);

  const regionName = useMemo(() => {
    return regions.find((item) => item.id === regionId)?.title || "";
  }, [regionId, regions]);

  const onOpenEditCansel = () => {
    onOpenEdit({
      id,
      status,
      sum: +sum,
      days,
      regionId,
      currency,
      type,
      maxPeriod,
      inMainInfo,
    });
  };

  return (
    <tr>
      <td>{id}</td>
      <td>
        {type} {type === "recurrent" && `(${maxPeriod} Mount)`}
      </td>
      <td>{inMainInfo ? "Yes" : "No"}</td>
      <td>
        {type === "recurrent" && maxPeriod
          ? `${sum} (${+sum / maxPeriod} per Mount)`
          : sum}
      </td>
      <td>{currency}</td>
      <td>{days === INFINITY_DAYS ? "Infinity" : days}</td>
      <td>
        <Tag minimal large intent="primary">
          {regionName}
        </Tag>
      </td>
      <td>
        <Status
          statusCustom={status}
          TagNamesCustom={{ active: "Active", disabled: "Disabled" }}
          TagsIntentCustom={{ active: "success", disabled: "danger" }}
        />
      </td>
      <td>
        <Button icon={"edit"} minimal onClick={onOpenEditCansel} />
      </td>
    </tr>
  );
};
