import { HTMLSelect, InputGroup } from "@blueprintjs/core";
import { useKinescopeVideos } from "Hooks/api/courses/kinescope/useKinescopeVideos";
import { FC, useMemo } from "react";
import { KinescopeSelectProps } from "./types";

export const KinescopeSelect: FC<KinescopeSelectProps> = ({
  isDisabled,
  onChangeData,
  data,
  ...props
}) => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChangeData(e.target.value);
  };

  const onSelectVideol: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    onChangeData(e.target.value);
  };

  const { data: allVideos, isLoading } = useKinescopeVideos();

  const videoOptions = useMemo(() => {
    return allVideos?.map((i: any) => ({ id: i.id, title: i.title })) || [];
  }, [allVideos]);

  return (
    <div>
      <HTMLSelect
        disabled={isDisabled || isLoading}
        fill
        style={{ marginBottom: 15 }}
        value={data}
        onChange={onSelectVideol}
      >
        <option value={""}>Choose...</option>
        {videoOptions.map((i: any) => (
          <option value={i.id}>{i.title}</option>
        ))}
      </HTMLSelect>
      <InputGroup
        disabled={isDisabled}
        value={data}
        onChange={onChange}
        id={"data"}
        {...props}
      />
    </div>
  );
};
