import {
  Button,
  Card,
  FormGroup,
  HTMLSelect,
  InputGroup,
} from "@blueprintjs/core";
import { useAddAdmin } from "Hooks/api/adminst";
import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "Styles/pageStyles/admins/addEditAdmin.module.css";

export const AddAdminPage: FC = () => {
  const navigate = useNavigate();

  const [data, setData] = useState(initalDataAdmin);
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (data) {
      setData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const onSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (data) {
      setData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const { isLoading: isDisabled, errors, mutate } = useAddAdmin();

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (data) {
      mutate(data);
    }
  };

  const error = useCallback(
    (name: string) => {
      const error = errors.find((e) => e.name === name);
      if (error) {
        return { helperText: error.message, intent: "danger" as any };
      }
      return;
    },
    [errors]
  );

  return (
    <div className={styles["rowContent"]}>
      <Card className={styles["form"]}>
        <form autoComplete="off" onSubmit={onSubmit}>
          <div className={styles["form_title"]}>
            <Button icon={"arrow-left"} minimal onClick={() => navigate(-1)} />
            <h2>Add admin</h2>
          </div>
          <div>
            <FormGroup label={"Name"} labelFor={"name"} {...error("name")}>
              <InputGroup
                disabled={isDisabled}
                value={data?.name || ""}
                onChange={onChange}
                id={"name"}
                {...error("name")}
              />
            </FormGroup>
            <FormGroup label={"FullName"} {...error("fullName")}>
              <InputGroup
                disabled={isDisabled}
                value={data?.fullName || ""}
                onChange={onChange}
                id={"fullName"}
                {...error("fullName")}
              />
            </FormGroup>
            <FormGroup label={"Email"} {...error("email")}>
              <InputGroup
                disabled={isDisabled}
                value={data?.email || ""}
                onChange={onChange}
                id={"email"}
                autoComplete="off"
                {...error("email")}
              />
            </FormGroup>
            <FormGroup label={"Status"} {...error("status")}>
              <HTMLSelect
                fill
                disabled={isDisabled}
                value={data?.status || ""}
                onChange={onSelect}
                id={"status"}
                {...error("status")}
              >
                <option value={"active"}>Active</option>
                <option value={"paused"}>Paused</option>
                <option value={"banned"}>Banned</option>
              </HTMLSelect>
            </FormGroup>
            <FormGroup label={"Password"} {...error("password")}>
              <InputGroup
                disabled={isDisabled}
                value={data?.password || ""}
                onChange={onChange}
                id={"password"}
                autoComplete="off"
                type="password"
                {...error("password")}
              />
            </FormGroup>
            <div className={styles["btnRow"]}>
              <Button intent={"primary"} type={"submit"} loading={isDisabled}>
                SAVE
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export interface IAddAdmin {
  name: string;
  fullName: string;
  email: string;
  password: string;
  status: string;
}

const initalDataAdmin = {
  name: "",
  fullName: "",
  email: "",
  password: "",
  status: "active",
};
