import { RegionsServices } from "Helpers/api/Regions";
import { useAppDispatch } from "Hooks/redux";
import { useState } from "react";
import { useMutation } from "react-query";
import { IRegion, IValidError } from "Types/common";

export const useAddEditRegions = (closeModal: () => void) => {
  const { getRegions } = useAppDispatch();

  const [errors, setErrors] = useState<IValidError[]>([]);

  const { isLoading, mutate } = useMutation(
    (data: IRegion) => {
      if (data.id) {
        return RegionsServices.editRegion(data);
      }
      return RegionsServices.createRegion(data);
    },
    {
      onMutate: () => {
        setErrors([]);
      },
      onSuccess: async () => {
        await getRegions();
        closeModal();
      },
      onError: (errorsRes: IValidError[]) => {
        if (Array.isArray(errorsRes)) {
          setErrors(errorsRes);
        }
      },
    }
  );

  return { errors, isDisabled: isLoading, mutate, setErrors };
};
