import { Button, Card, HTMLSelect, Label, Tag } from "@blueprintjs/core";
import { Loading } from "Componens/common";
import { URL_SUPPORT } from "Constants/URL";
import { useSupport, useSupportUpdate } from "Hooks/api/support";
import { useAppSelector } from "Hooks/redux";
import moment from "moment";
import { FC, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import styles from "Styles/pageStyles/admins/supportItem.module.css";
import { TSupportStatus } from "Types/common";

export const Ticket: FC = () => {
  const { id } = useParams<string>();

  const regions = useAppSelector((s) => s.App.regions);

  const { data, isLoading } = useSupport(id as string);

  const { isDiabled, mutate } = useSupportUpdate(id as string);

  const onChangeStatus: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    mutate(e.target.value as TSupportStatus);
  };

  const RegionName = useMemo(() => {
    return regions.find((i) => i.id === data?.regionId)?.title;
  }, [data?.regionId, regions]);

  if (isLoading || !data) {
    return <Loading fullScreen />;
  }

  return (
    <Card>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link to={URL_SUPPORT}>
          <Button icon={"arrow-left"} minimal />
        </Link>
        <h1>TICKET: {id}</h1>
      </div>
      <div>
        <ItemData title="Name" data={data.name} />
        {data.userId && <ItemData title="User ID" data={data.userId} />}
        <ItemData title="Email" data={data.email} />
        <ItemData title="Phone" data={data.phone} />
        <ItemData title="Order №" data={data.orderNumber} />
        {RegionName && (
          <ItemData title="Region">
            <Tag minimal intent="primary">
              {RegionName}
            </Tag>
          </ItemData>
        )}
        <ItemData
          title="Create At"
          data={moment(data.createdAt).format("DD.MM.YYYY")}
        />
        <ItemData title="Status">
          <HTMLSelect
            value={data.status}
            onChange={onChangeStatus}
            disabled={isDiabled}
          >
            <option value={"new"}>New</option>
            <option value={"work"}>Work</option>
            <option value={"closed"}>Closed</option>
            <option value={"spam"}>Spam</option>
          </HTMLSelect>
        </ItemData>
        <ItemData title="Description" data={data.description} />
      </div>
    </Card>
  );
};

interface IitemData {
  title: string;
  data?: any;
}

const ItemData: FC<IitemData> = ({ title, data, children }) => {
  return (
    <div className={styles["wrapper"]}>
      <Label className={styles["label"]}>{title}</Label>
      <div>{data || children}</div>
    </div>
  );
};
