import { WrapperAdmin } from "Componens/main/wrapper";
import { FC } from "react";
import { EditAdmin } from "./AdminPages/AdminsPages/EditAdmin";
import { AdminListPage } from "./AdminPages/AdminsPages/adminListPage";
import { Route, Routes } from "react-router-dom";
import {
  URL_ADMINS,
  URL_ADMINS_$ID,
  URL_ADMINS_ADD,
  URL_COURSES,
  URL_COURSES_$ID,
  URL_HOME,
  URL_SETTINGS,
  URL_SUPPORT,
  URL_USERS,
} from "Constants/URL";
import { AddAdminPage } from "./AdminPages/AdminsPages/AddAdminPage";
import { CoursesListPage } from "./AdminPages/Courses/CoursesList";
import { CourseItemPage } from "./AdminPages/Courses/CourseItem";
import { SettingsPage } from "./AdminPages/Settings";
import { SupportPage } from "./AdminPages/Support";
import { Ticket } from "./AdminPages/Support/Ticket";
import { UsersPages } from "./AdminPages/UsersPages";

export const AdminPages: FC = () => {
  return (
    <WrapperAdmin>
      <Routes>
        <Route path={URL_HOME} element={<>dashboard</>} />
        <Route path={URL_ADMINS} element={<AdminListPage />} />
        <Route path={URL_ADMINS_$ID} element={<EditAdmin />} />
        <Route path={URL_ADMINS_ADD} element={<AddAdminPage />} />

        <Route path={URL_COURSES} element={<CoursesListPage />} />
        <Route path={URL_COURSES_$ID} element={<CourseItemPage />} />

        <Route path={URL_SETTINGS} element={<SettingsPage />} />
        <Route path={URL_SUPPORT}>
          <Route element={<SupportPage />} index />
          <Route path=":id" element={<Ticket />} />
        </Route>

        <Route path={URL_USERS} element={<UsersPages />} />

        {/* <Route path="*" element={<ErrorPage />} /> */}
      </Routes>
    </WrapperAdmin>
  );
};
