export const URL_HOME = "/";

// URL Login
export const URL_LOGIN = "/login";

// URL Admins
export const URL_ADMINS = "/admins";
export const URL_ADMINS_ADD = "/admins/new";
export const URL_ADMINS_$ID = "/admins/:id";
export const URL_ADMINS_ID = (id: number | string) => URL_ADMINS + "/" + id;

//URL Courses
export const URL_COURSES = "/courses";
export const URL_COURSES_ID = (id: string | number) => URL_COURSES + "/" + id;
export const URL_COURSES_$ID = URL_COURSES + "/:id/*";

export const URL_COURSES_$ID_MAIN_INFO = "/";
export const URL_COURSES_$ID_MODULES = "/modules/*";
export const URL_COURSES_$ID_MODULES_$ID = "/modules/:idModule";
export const URL_COURSES_$ID_PRICES = "/prices";
export const URL_COURSES_$ID_FILES = "/files";
export const URL_COURSES_$ID_ANNOUNCE = "/announce";

// URL Settings
export const URL_SETTINGS = "/settings";

// URL Support
export const URL_SUPPORT = "/support";
export const URL_SUPPORT_ID = (id: string | number) => URL_SUPPORT + "/" + id;

// URL Users
export const URL_USERS = "/users";
export const URL_USERS_ID = (id: string | number) => URL_USERS + "/" + id;
