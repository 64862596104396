import axios from "axios";
import { API_USERS } from "Constants/API/Users";
import { HttpHeadersAuthorization } from "Helpers/common";

export const UsersServices = {
  getUsers: (filter: Object) =>
    axios
      .get(API_USERS, {
        headers: HttpHeadersAuthorization(),
        params: filter,
      })
      .then((res) => res.data),
};
