import { Button, Card, HTMLTable } from "@blueprintjs/core";
import { Loading } from "Componens/common";
import { CoursesItem } from "Componens/courses/coursesItem";
import { ModalMainCourse } from "Componens/courses/modalMainCourse";
import {
  IAddCourse,
  initialDataAddCourse,
} from "Componens/courses/modalMainCourse/constants";
import { useCourseList } from "Hooks/api/courses";
import { FC, useState } from "react";

import styles from "Styles/pageStyles/list.module.css";

export const CoursesListPage: FC = () => {
  const { data, isLoading } = useCourseList();

  const [editData, setEditData] = useState<undefined | IAddCourse>(undefined);

  const onOpenCloseModal = () => {
    setEditData(initialDataAddCourse);
  };

  if (isLoading) {
    return <Loading fullScreen />;
  }

  return (
    <div>
      <Card>
        <div className={styles["row"]}>
          <h1>Courses</h1>
          <Button intent={"primary"} icon={"add"} onClick={onOpenCloseModal}>
            Add Course
          </Button>
        </div>
        <HTMLTable style={{ width: "100%" }}>
          <thead>
            <tr>
              <td>Id</td>
              <td>Title</td>
              <td>Region only</td>
              <td>Status</td>
              <td>Options</td>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <CoursesItem {...item} key={index + "courseItem"} />
            ))}
          </tbody>
        </HTMLTable>
        <ModalMainCourse
          isOpen={!!editData}
          data={editData}
          setData={setEditData}
        />
      </Card>
    </div>
  );
};
