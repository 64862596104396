import axios, { AxiosError } from "axios";
import { ICourseModuleEdit } from "Componens/courses/coursecBlocks/modules/EditModules/types";
import { IEditDataMainModules } from "Componens/courses/coursecBlocks/modules/Modal";
import {
  API_MAIN_MODULES,
  API_MAIN_MODULES_ID,
  API_MODULES,
} from "Constants/API/Courses";
import { HttpHeadersAuthorization } from "Helpers/common";
import { ICourseMainModule, ICourseModule } from "Types/courses";
import { ISuccessRes, IValidErrorRes } from "Types/responce";

export const mainModulesServices = {
  async createModule(courseId: string, data: IEditDataMainModules) {
    return axios
      .post(API_MAIN_MODULES(courseId), data, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => courseId)
      .catch((e: AxiosError<IValidErrorRes>) => {
        if (e.response?.data.errors) {
          throw e.response.data.errors;
        }
        throw e;
      });
  },
  async updateModule(courseId: string, data: IEditDataMainModules) {
    return axios
      .put(API_MAIN_MODULES(courseId), data, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => courseId)
      .catch((e: AxiosError<IValidErrorRes>) => {
        if (e.response?.data.errors) {
          throw e.response.data.errors;
        }
        throw e;
      });
  },
  async getMainModule(id: string, courseId: string) {
    return axios
      .get<ISuccessRes<ICourseMainModule>>(API_MAIN_MODULES_ID(id, courseId), {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => res.data.data)
      .catch((e) => {
        throw e;
      });
  },
};

interface IDataUpdateModules {
  modules: (ICourseModuleEdit | ICourseModule)[];
  id: string;
}

export const modulesServices = {
  async updateModules(idCourse: string, data: IDataUpdateModules) {
    return axios
      .post(API_MODULES(idCourse), data, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => ({ id: idCourse, mainModuleId: data.id }))
      .catch((e: AxiosError<IValidErrorRes>) => {
        if (e.response?.data.errors) {
          throw e.response.data.errors;
        }
        throw e;
      });
  },
  async deleteModule(idCourse: string, idModule: number, mainModuleId: string) {
    return axios
      .delete(API_MODULES(idCourse) + "/" + idModule, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => ({ id: idCourse, mainModuleId }))
      .catch((e: AxiosError<IValidErrorRes>) => {
        if (e.response?.data.errors) {
          throw e.response.data.errors;
        }
        throw e;
      });
  },
};
