import { FC, useMemo } from "react";

import { Button, Card, Tag } from "@blueprintjs/core";
import { Status } from "Componens/common";

import { useAppSelector } from "Hooks/redux";

import { AnnounceItemProps } from "./types";

import styles from "./styles.module.css";
import { getUrlImg } from "Helpers/common";

export const AnnounceItem: FC<AnnounceItemProps> = ({
  status,
  regionId,
  title,
  description,
  id,
  img,
  onClickEdit,
}) => {
  const regions = useAppSelector((s) => s.App.regions);

  const regionName = useMemo(() => {
    return regions.find((item) => item.id === regionId)?.title || "";
  }, [regionId, regions]);

  return (
    <Card className={styles["item"]}>
      <div className={styles["row"]}>
        <div>
          <span className={styles["status"]}>
            Status: <Status status={status} />
          </span>
          Lang:{" "}
          <Tag intent="primary" minimal large>
            {regionName}
          </Tag>
        </div>
        <Button
          icon={"edit"}
          minimal
          onClick={() =>
            onClickEdit({
              id,
              img: img || "",
              description,
              regionId,
              title,
              status,
            })
          }
        />
      </div>
      <div>{title}</div>
      <p>{description}</p>
      {img && (
        <div>
          <img src={getUrlImg(img)} alt="" className={styles["img"]} />
        </div>
      )}
    </Card>
  );
};
