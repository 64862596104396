import { Button, Card, HTMLTable } from "@blueprintjs/core";
import { Loading, Status } from "Componens/common";
import { URL_ADMINS_ADD, URL_ADMINS_ID } from "Constants/URL";
import { useAdmins } from "Hooks/api/adminst";
import { FC } from "react";
import { Link } from "react-router-dom";
import { IUser } from "Types/login";

import styles from "Styles/pageStyles/list.module.css";

export const AdminListPage: FC = () => {
  const { data, isLoading } = useAdmins();

  if (isLoading) {
    return <Loading fullScreen />;
  }

  // if(isError) {}

  return (
    <div>
      <Card>
        <div className={styles["row"]}>
          <h1>Admins</h1>
          <Link to={URL_ADMINS_ADD}>
            <Button intent={"primary"} icon={"add"}>
              Add
            </Button>
          </Link>
        </div>
        <HTMLTable className={styles["table"]}>
          <thead>
            <tr>
              <th>id</th>
              <th>Name</th>
              <th>FullName</th>
              <th>Email</th>
              <th>Created at</th>
              <th>Staus</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((user, index) => (
              <TableItem key={index + "user"} {...user} />
            ))}
          </tbody>
        </HTMLTable>
      </Card>
    </div>
  );
};

const TableItem: FC<IUser> = ({
  id,
  name,
  fullName,
  email,
  createdAt,
  status,
}) => {
  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{fullName}</td>
      <td>{email}</td>
      <td>{createdAt}</td>
      <td>
        <Status
          statusCustom={status}
          TagNamesCustom={{
            active: "Active",
            paused: "Paused",
            banned: "Banned",
          }}
          TagsIntentCustom={{
            active: "success",
            paused: "warning",
            banned: "danger",
          }}
        />
      </td>
      <td>
        <Link to={URL_ADMINS_ID(id)}>
          <Button icon={"arrow-right"} intent={"none"} minimal />
        </Link>
      </td>
    </tr>
  );
};
