import { FC } from "react";

import Video from "./video.svg";
import Image from "./image.svg";
import Text from "./text.svg";
import Logo from "./logo.svg";
import Profile from "./profile.svg";

const Icons = {
  video: Video,
  image: Image,
  text: Text,
  logo: Logo,
  profile: Profile,
};

interface IconProps {
  icon: "video" | "image" | "text" | "logo" | "profile";
  className?: string;
}

export const Icon: FC<IconProps> = ({ icon, className = "" }) => {
  return <img alt="icon" src={Icons[icon]} className={className} />;
};
