import {
  Button,
  Card,
  FormGroup,
  HTMLSelect,
  InputGroup,
} from "@blueprintjs/core";
import { URL_ADMINS } from "Constants/URL";
import { useAdmin, useEditAdmin } from "Hooks/api/adminst";
import { FC, useCallback } from "react";
import { Link, useParams } from "react-router-dom";

import styles from "Styles/pageStyles/admins/addEditAdmin.module.css";

export const EditAdmin: FC = () => {
  const { id } = useParams<string>();

  const { isLoading, data, setData } = useAdmin(id as string);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (data) {
      setData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const onSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (data) {
      setData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const { isLoading: isDisabled, errors, mutate } = useEditAdmin();

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (data) {
      mutate(data);
    }
  };

  const error = useCallback(
    (name: string) => {
      const error = errors.find((e) => e.name === name);
      if (error) {
        return { helperText: error.message, intent: "danger" as any };
      }
      return;
    },
    [errors]
  );

  return (
    <div className={styles["rowContent"]}>
      <Card className={styles["form"]}>
        <form onSubmit={onSubmit}>
          <div className={styles["form_title"]}>
            <Link to={URL_ADMINS}>
              <Button icon={"arrow-left"} minimal />
            </Link>
            <h2>Edit Admin #{id}</h2>
          </div>
          <div>
            <FormGroup label={"Name"} labelFor={"name"} {...error("name")}>
              <InputGroup
                disabled={isLoading || isDisabled}
                value={data?.name || ""}
                onChange={onChange}
                id={"name"}
                {...error("name")}
              />
            </FormGroup>
            <FormGroup label={"FullName"} {...error("fullName")}>
              <InputGroup
                disabled={isLoading || isDisabled}
                value={data?.fullName || ""}
                onChange={onChange}
                id={"fullName"}
                {...error("fullName")}
              />
            </FormGroup>
            <FormGroup label={"Email"} {...error("email")}>
              <InputGroup
                disabled={isLoading || isDisabled}
                value={data?.email || ""}
                onChange={onChange}
                id={"email"}
                {...error("email")}
              />
            </FormGroup>
            <FormGroup label={"Status"} {...error("status")}>
              <HTMLSelect
                fill
                disabled={isLoading || isDisabled}
                value={data?.status || ""}
                onChange={onSelect}
                id={"status"}
                {...error("status")}
              >
                <option value={"active"}>Active</option>
                <option value={"paused"}>Paused</option>
                <option value={"banned"}>Banned</option>
              </HTMLSelect>
            </FormGroup>
            <div className={styles["btnRow"]}>
              <Button
                intent={"primary"}
                type={"submit"}
                loading={isLoading || isDisabled}
              >
                SAVE
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};
