import React, { useEffect } from "react";
import { LoginPage } from "Pages/login";
import { Route, Routes } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "Hooks/redux";
import { Loading } from "Componens/common/Loading";
import { AdminPages } from "Pages";

function App() {
  const { checkAuth } = useAppDispatch();

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isAuth, isLoadedApp } = useAppSelector((state) => state.App);

  return (
    <div className="App">
      {isLoadedApp ? (
        <div className="container body">
          <Routes>
            {isAuth ? (
              <Route path="*" element={<AdminPages />} />
            ) : (
              <Route path={"*"} element={<LoginPage />} />
            )}

            {/* <Route path="*" element={<ErrorPage />} /> */}
          </Routes>
        </div>
      ) : (
        <Loading fullScreen />
      )}
    </div>
  );
}

export default App;
