import { URL_ADMINS_ID } from "Constants/URL";
import { AdminsService } from "Helpers/api/Admins";
import { IAddAdmin } from "Pages/AdminPages/AdminsPages/AddAdminPage";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { IValidError } from "Types/common";
import { IUser } from "Types/login";

export const useAdmins = () => {
  const { isError, isLoading, data } = useQuery(
    ["admin", "list"],
    AdminsService.getAdminList
  );

  return { isError, isLoading, data };
};

export const useAdmin = (id: string) => {
  const {
    isError,
    isLoading,
    data: DataRes,
  } = useQuery(["admin", id], () => AdminsService.getAdmin(id), {
    enabled: !!id,
  });

  useEffect(() => {
    setData(DataRes);
  }, [DataRes]);

  const [data, setData] = useState<IUser | undefined>(DataRes);

  return { isError, isLoading, data, setData };
};

export const useEditAdmin = () => {
  const queryClient = useQueryClient();

  const [errors, setErrors] = useState<IValidError[]>([]);

  const { isLoading, mutate } = useMutation(
    (data: IUser) =>
      AdminsService.editAdmin(data.id as unknown as string, data),
    {
      onMutate: () => {
        setErrors([]);
      },
      onSuccess: (id) => {
        queryClient.refetchQueries(["admin", "list"]);
        queryClient.invalidateQueries(["admin", id.toString()]);
      },
      onError: (errorsRes: IValidError[]) => {
        setErrors(errorsRes);
      },
    }
  );

  return { errors, isLoading, mutate };
};

export const useAddAdmin = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<IValidError[]>([]);

  const { isLoading, mutate } = useMutation(
    (data: IAddAdmin) => AdminsService.addAdmin(data),
    {
      onMutate: () => {
        setErrors([]);
      },
      onSuccess: (id) => {
        queryClient.refetchQueries(["admin", "list"]);
        navigate(URL_ADMINS_ID(id));
      },
      onError: (errorsRes: IValidError[]) => {
        setErrors(errorsRes);
      },
    }
  );

  return { errors, isLoading, mutate };
};
