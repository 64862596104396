import { FC, useMemo } from "react";

import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  InputGroup,
  ProgressBar,
  // TextArea,
} from "@blueprintjs/core";
import { useError } from "Hooks/common";
import { IEditContent } from "../types";
import { useContentSave, useDeleteContent } from "Hooks/api/courses/content";
import { useParams } from "react-router-dom";

import { KinescopeSelect } from "./KinescopeSelect";
import { KinescopeSelectStreams } from "./KinescopeSelectStreams";
import { TextContentModal } from "./TextContentModal";
import { FileContentModal } from "./FileContentModal";
import { LinkContentModal } from "./LinkContentModal";
import { AnnounceContentModal } from "./AnnounceContentModal";
import { FaceCastContentModal } from "./FaceCastContentModal";

interface IContentModal {
  data: IEditContent | undefined;
  setEditData: React.Dispatch<React.SetStateAction<IEditContent | undefined>>;
  mainModuleId: number;
}

export const ContentModal: FC<IContentModal> = ({
  data,
  setEditData,
  mainModuleId,
}) => {
  const onCloseModal = () => {
    if (isDisabled || persent) return;
    setEditData(undefined);
    setErrors([]);
  };

  const {
    mutate,
    errors,
    setErrors,
    persent,
    isDisabled: isDisabledSave,
  } = useContentSave(onCloseModal);

  const error = useError(errors);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (data) {
      setEditData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const onSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (data) {
      setEditData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const onSelectType: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (data) {
      setEditData({
        ...data,
        [e.target.id]: e.target.value,
        data: "",
        fileId: "ADDFILE",
      });
    }
  };

  const onChangeText = (value: string) => {
    if (data) {
      setEditData({ ...data, data: value });
    }
  };

  const onChangeFile: React.FormEventHandler<HTMLInputElement> = (e) => {
    if (data) {
      setEditData({ ...data, data: e.currentTarget.files?.[0] || "" });
    }
  };

  const onChangeContent = (content: any) => {
    if (data) {
      setEditData({ ...data, data: content });
    }
  };

  const { id: courseId } = useParams<string>();

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (!data || !courseId) return;
    formData.append("title", data.title);
    formData.append("type", data.type);
    formData.append("courseModuleId", data.courseModuleId.toString());
    formData.append("status", data.status);

    if (data.data) {
      switch (data.type) {
        case "stream-kinescope":
          formData.append("data", data.data);
          break;

        case "link":
          formData.append("data", JSON.stringify(data.data));
          break;

        case "announce":
          formData.append("data", JSON.stringify(data.data));
          break;

        default:
          formData.append("data", data.data);
          break;
      }
    }

    if (data.fileId && data.fileId !== "ADDFILE") {
      formData.append("fileId", data.fileId);
    }
    if (data.id !== "new") {
      formData.append("id", data.id.toString());
    }
    mutate({
      courseId,
      data: formData,
      mainModuleId,
      isNew: data.id === "new",
    });
  };

  const { isLoading: isDisabledDelete, mutate: deleteContent } =
    useDeleteContent(onCloseModal);

  const isDisabled = useMemo(() => {
    return isDisabledSave || isDisabledDelete;
  }, [isDisabledSave, isDisabledDelete]);

  const onClickDelete = () => {
    if (!data?.id || data?.id === "new" || !courseId) return;

    deleteContent({
      courseId,
      mainModuleId,
      contentId: data.id as unknown as number,
    });
  };

  return (
    <Dialog
      isOpen={!!data}
      onClose={onCloseModal}
      title={
        (data?.id as unknown as string) === "new"
          ? "New Content"
          : "Edit content"
      }
    >
      <form onSubmit={onSubmit}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={"Title"} {...error("title")} labelFor={"title"}>
            <InputGroup
              disabled={isDisabled}
              value={data?.title || ""}
              onChange={onChange}
              id={"title"}
              {...error("title")}
            />
          </FormGroup>
          <FormGroup label={"Type"} {...error("type")} labelFor={"type"}>
            <HTMLSelect
              id="type"
              fill
              value={data?.type}
              onChange={onSelectType}
              disabled={isDisabled}
              {...error("type")}
            >
              <option value={"text"}>Text</option>
              <option value={"video"}>Video</option>
              <option value={"video-kinescope"}>Video Kinescope</option>
              {/* <option value={"stream-kinescope"}>Stream Kinescope</option> */}
              <option value={"stream-faceCast"}>Stream FaceCast</option>
              <option value={"file"}>File</option>
              <option value={"image"}>Image</option>
              <option value={"link"}>Link</option>
              <option value={"announce"}>Announce</option>
            </HTMLSelect>
          </FormGroup>
          <FormGroup
            label={
              data?.type === "video-kinescope" ||
              data?.type === "stream-kinescope"
                ? "Video Id"
                : "Content"
            }
            {...error("data")}
            labelFor={"data"}
          >
            {data?.type === "text" && (
              <TextContentModal
                data={data}
                onChangeText={onChangeText}
                isDisabled={isDisabled}
              />
            )}
            {data?.type === "video-kinescope" && (
              <KinescopeSelect
                isDisabled={isDisabled}
                onChangeData={onChangeText}
                data={(data?.data as string) || ""}
                {...error("data")}
              />
            )}
            {data?.type === "stream-kinescope" && (
              <KinescopeSelectStreams
                isDisabled={isDisabled}
                onChangeData={onChangeText}
                data={(data?.data as string) || ""}
                {...error("data")}
              />
            )}
            {data?.type === "file" && (
              <FileContentModal
                data={data}
                onSelectFile={onSelect}
                isDisabled={isDisabled}
                error={error}
                onChangeFile={onChangeFile}
              />
            )}
            {data?.type === "video" && (
              <FileContentModal
                data={data}
                onSelectFile={onSelect}
                isDisabled={isDisabled}
                error={error}
                onChangeFile={onChangeFile}
              />
            )}
            {data?.type === "link" && (
              <LinkContentModal
                data={data.data as any}
                onChange={onChangeContent}
                isDisabled={isDisabled}
                error={error}
              />
            )}
            {data?.type === "announce" && (
              <AnnounceContentModal
                data={data.data as any}
                onChange={onChangeContent}
                isDisabled={isDisabled}
                error={error}
              />
            )}
            {data?.type === "stream-faceCast" && (
              <FaceCastContentModal
                data={data.data as string}
                onChange={onChangeText}
                error={error}
                isDisabled={isDisabled}
              />
            )}
          </FormGroup>

          <FormGroup label={"Status"} {...error("status")} labelFor={"status"}>
            <HTMLSelect
              id="status"
              fill
              value={data?.status}
              onChange={onSelect}
              disabled={isDisabled}
              {...error("status")}
            >
              <option value={"active"}>Active</option>
              <option value={"draft"}>Draft</option>
              <option value={"closed"}>Closed</option>
            </HTMLSelect>
          </FormGroup>
          {persent && (
            <ProgressBar
              intent={"success"}
              stripes={false}
              value={persent / 100}
            />
          )}
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {data?.id !== "new" ? (
              <Button
                onClick={onClickDelete}
                intent={"danger"}
                type={"button"}
                disabled={isDisabled}
                icon={"trash"}
              >
                Delete
              </Button>
            ) : (
              <div />
            )}
            <Button icon={"saved"} type={"submit"} disabled={isDisabled}>
              {(data?.id as unknown as string) !== "new"
                ? "Save"
                : "Add Content"}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
