import axios from "axios";
import {
  API_KINESCOPE_STREAMS,
  API_KINESCOPE_VIDEOS,
} from "Constants/API/Courses";
import { HttpHeadersAuthorization } from "Helpers/common";

export const kinescopeServices = {
  getAllVideos: () =>
    axios
      .get(API_KINESCOPE_VIDEOS, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => res.data.data),
  getAllStreams: () =>
    axios
      .get(API_KINESCOPE_STREAMS, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => res.data.data),
};
