import { FC } from "react";
import { Header } from "../header";
import { Menu } from "../menu";
import styles from "./index.module.css";

interface WrapperAdminProps {
  children: React.ReactNode;
}

export const WrapperAdmin: FC<WrapperAdminProps> = ({ children }) => {
  return (
    <div className={styles["wrapper"]}>
      <div>
        <Menu />
      </div>
      <div className={styles["main"]}>
        <Header />
        <div className={styles["content"]}>{children}</div>
      </div>
    </div>
  );
};
