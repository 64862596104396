import { Card } from "@blueprintjs/core";
import { Icon } from "Componens/common/Icon";
import {
  URL_ADMINS,
  URL_COURSES,
  URL_HOME,
  URL_SETTINGS,
  URL_SUPPORT,
} from "Constants/URL";
import { FC } from "react";
import { NavLink } from "react-router-dom";

import styles from "./index.module.css";

export const Menu: FC = () => {
  return (
    <Card className={styles["menu"]}>
      <div className={styles["menu__logo"]}>
        <Icon icon="logo" />
      </div>
      <div>
        <div>
          <NavLink to={URL_HOME}>Dashboard</NavLink>
        </div>
        <div>
          <NavLink to={URL_ADMINS}>Admins</NavLink>
        </div>
        <div>
          <NavLink to={URL_COURSES}>Courses</NavLink>
        </div>
        <div>Transactions</div>
        <div>
          <NavLink to={URL_SUPPORT}>Support</NavLink>
        </div>
        <div>
          <NavLink to={URL_SETTINGS}>Settings</NavLink>
        </div>
      </div>
    </Card>
  );
};
