import { TCourseType, TСourseStatus } from "Types/common";

export interface IAddCourse {
  title: string;
  regionOnly: boolean;
  id?: number;
  status?: TСourseStatus;
  type: TCourseType;
  isAnnouncement: boolean;
}

export const initialDataAddCourse: IAddCourse = {
  title: "",
  type: "course",
  regionOnly: false,
  isAnnouncement: true,
};
