import axios, { AxiosError } from "axios";
import { API_FILES_ID } from "Constants/API/Courses";
import { HttpHeadersAuthorization } from "Helpers/common";
import { IValidErrorRes } from "Types/responce";

export const FilesServices = {
  async createFile(courseId: string, data: any, setPersent?: any) {
    return axios
      .post(API_FILES_ID(courseId), data, {
        headers: HttpHeadersAuthorization(),
        onUploadProgress: (progressEvent: {
          loaded: number;
          total: number;
        }) => {
          setPersent(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
      })
      .then((res) => courseId)
      .catch((e: AxiosError<IValidErrorRes>) => {
        if (e.response?.data.errors) {
          throw e.response?.data.errors;
        }
        throw e;
      });
  },
  async deleteFile(courseId: string, fileId: string) {
    return axios
      .delete(API_FILES_ID(courseId) + "/" + fileId, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => courseId)
      .catch((e: AxiosError<IValidErrorRes>) => {
        if (e.response?.data.errors) {
          throw e.response?.data.errors;
        }
        throw e;
      });
  },
};
