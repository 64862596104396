import { FormGroup, InputGroup } from "@blueprintjs/core";
import { FC } from "react";

interface FaceCastContentModalProps {
  error: any;
  data: string;
  isDisabled: boolean;
  onChange: (value: string) => void;
}

export const FaceCastContentModal: FC<FaceCastContentModalProps> = ({
  error,
  data,
  isDisabled,
  onChange,
}) => {
  return (
    <FormGroup label={"FaceCast ID"} {...error("data")} labelFor={"data"}>
      <InputGroup
        disabled={isDisabled}
        value={data}
        onChange={(e) => onChange(e.target.value)}
        id={"data"}
      />
    </FormGroup>
  );
};
