import axios, { AxiosError } from "axios";
import { IEditPrice } from "Componens/courses/coursecBlocks/pricesInfo";
import { API_PRICES } from "Constants/API/Courses";
import { HttpHeadersAuthorization } from "Helpers/common";
import { IValidErrorRes } from "Types/responce";

export const PricesServices = {
  async editPrice(courseId: string, data: IEditPrice) {
    return axios
      .put(API_PRICES(courseId), data, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => courseId)
      .catch((e: AxiosError<IValidErrorRes>) => {
        throw e.response?.data.errors;
      });
  },
  async addPrice(courseId: string, data: IEditPrice) {
    return axios
      .post(API_PRICES(courseId), data, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => courseId)
      .catch((e: AxiosError<IValidErrorRes>) => {
        throw e.response?.data.errors;
      });
  },
};
