import axios, { AxiosError } from "axios";
import { IEditDataMainInfo } from "Componens/courses/coursecBlocks/mainInfos";
import { API_MAIN_INFO } from "Constants/API/Courses";
import { HttpHeadersAuthorization } from "Helpers/common";
import { IValidErrorRes } from "Types/responce";

export const MainInfoServices = {
  async addMainInfo(courceId: string, data: IEditDataMainInfo) {
    return axios
      .post(API_MAIN_INFO(courceId), setJsonOrFormData(data), {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => courceId)
      .catch((e: AxiosError<IValidErrorRes>) => {
        throw e.response?.data.errors;
      });
  },
  async editMainInfo(courceId: string, data: IEditDataMainInfo) {
    return axios
      .put(API_MAIN_INFO(courceId), setJsonOrFormData(data), {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => courceId)
      .catch((e: AxiosError<IValidErrorRes>) => {
        throw e.response?.data.errors;
      });
  },
  async deleteMainInfo(courceId: string, mainInfoId: number) {
    return axios
      .delete(API_MAIN_INFO(courceId) + "/" + mainInfoId, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => courceId)
      .catch((e: AxiosError) => {
        throw e;
      });
  },
};

function setJsonOrFormData(data: IEditDataMainInfo) {
  if (typeof data.img === "object") {
    const formData = new FormData();

    Object.entries(data).forEach((item) => {
      const [key, value] = item;
      formData.append(key, value);
    });

    return formData;
  }
  return data;
}
