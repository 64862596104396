import { FC } from "react";
// @ts-ignore:disable-next-line
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore:disable-next-line
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IEditContent } from "../../types";

interface TextContentModalProps {
  data: IEditContent;
  onChangeText: (text: string) => void;
  isDisabled: boolean;
}

export const TextContentModal: FC<TextContentModalProps> = ({
  data,
  onChangeText,
  isDisabled,
}) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={typeof data?.data === "string" ? data.data : ""}
      onChange={(event: any, editor: any) => {
        const text = editor.getData();
        onChangeText(text);
      }}
      disabled={isDisabled}
    />
  );
};
