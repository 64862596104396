import { UsersServices } from "Helpers/api/Users";
import { useQuery } from "react-query";
import { IResList } from "Types/responce";
import { IUserFull } from "Types/users";

export const useUsers = (filter: Object) => {
  const { data, isLoading } = useQuery<IResList<IUserFull>>({
    queryFn: () => UsersServices.getUsers(filter),
    queryKey: ["users", filter],
  });

  return { data, isLoading };
};
