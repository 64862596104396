import { Icon } from "@blueprintjs/core";
import { useAppSelector } from "Hooks/redux";
import { FC } from "react";

import styles from "./index.module.css";

export const Header: FC = () => {
  const user = useAppSelector((state) => state.App.user);
  return (
    <header className={styles["header"]}>
      <div>
        <Icon
          icon={"user"}
          className={styles["header_icon"]}
          color={"#5f6b7c"}
        />
        {user?.name} {user?.fullName}
      </div>
    </header>
  );
};
