import { Button, Card, Collapse, Icon } from "@blueprintjs/core";
import classNames from "classnames";
import { Status } from "Componens/common";
import { FC, useState } from "react";
import { ICourseModule } from "Types/courses";
import { ICourseModuleEdit } from "../types";
import { ContentModal } from "./ContentModal";

import styles from "./index.module.css";
import { ItemContent } from "./ItemContent";
import { IEditContent } from "./types";

interface IItemModule {
  index: number;
  setEditModule: (data: ICourseModuleEdit) => void;
  data: ICourseModule;
}

export const ItemModule: FC<IItemModule> = ({ data, setEditModule, index }) => {
  const [openCollapse, setOpenCollapse] = useState(false);

  const onClickOpenCollapse = () => {
    setOpenCollapse((v) => !v);
  };

  const [editContent, setEditContent] = useState<IEditContent | undefined>();

  const onClickAddContent = () => {
    setEditContent({
      id: "new",
      title: "",
      type: "text",
      status: "draft",
      courseModuleId: data.id,
      data: "",
    });
  };

  return (
    <Card>
      <div className="row">
        <div onClick={onClickOpenCollapse} className={styles["collapse"]}>
          id:{data.id} {data.title} <Status status={data.status} />
          <Icon
            icon={"chevron-down"}
            intent={"primary"}
            className={classNames(styles["chevron"], {
              [styles["chevron-active"]]: openCollapse,
            })}
          />
        </div>
        <div>
          <Button
            icon={"edit"}
            minimal
            onClick={() => setEditModule({ ...data, editIndex: index })}
          />
        </div>
      </div>
      <Collapse isOpen={openCollapse}>
        <div>
          <div className={styles["description"]}>{data.description}</div>
          <div>
            <h2>
              Content:{" "}
              <Button icon={"add"} onClick={onClickAddContent} minimal />
            </h2>
            <div>
              {data.courseContents.map((item, index) => (
                <ItemContent
                  {...item}
                  key={index + "content" + item.id}
                  setEditData={setEditContent}
                />
              ))}
            </div>
          </div>
        </div>
      </Collapse>
      <ContentModal
        data={editContent}
        setEditData={setEditContent}
        mainModuleId={data.courceModulesMainId}
      />
    </Card>
  );
};
