import { Button, HTMLTable, Tag } from "@blueprintjs/core";
import { Status } from "Componens/common";
import { useGetRegionName } from "Hooks/common";
import { FC, useMemo, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { ICourseMainModule } from "Types/courses";
import { EditModulesBlock } from "./EditModules";
import { IEditDataMainModules, ModalMainModules } from "./Modal";

interface IMoluesCourse {
  courceModulesMains: ICourseMainModule[];
  selectedRegion: number;
}

export const ModulesCourse: FC<IMoluesCourse> = ({
  courceModulesMains,
  selectedRegion = 1,
}) => {
  const { pathname } = useLocation();
  const [editData, setEditData] = useState<undefined | IEditDataMainModules>();

  const mainModulesRegion = useMemo(() => {
    let mainInfo = courceModulesMains
      .filter((item) => item.regionId === selectedRegion)
      .sort((a, b) => (a.status > b.status ? 1 : -1));

    // if (mainInfo.length === 0) {
    //   mainInfo = courceModulesMains
    //     .filter((item) => item.regionId === 1 && item.status === "active")
    //     .sort((a, b) => (a.status > b.status ? 1 : -1));
    // }

    return mainInfo;
  }, [courceModulesMains, selectedRegion]);

  const addMainModules = () => {
    setEditData({ title: "", status: "active", regionId: selectedRegion });
  };

  return (
    <div>
      <h2>
        Modules{" "}
        {!pathname.includes("/modules/") && (
          <Button icon={"add"} minimal onClick={addMainModules} />
        )}
      </h2>
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <HTMLTable width={"100%"}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Status</th>
                    <th>Region</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  {mainModulesRegion.map((item, index) => (
                    <ModuleItem
                      {...item}
                      key={index + "module"}
                      setEditData={setEditData}
                    />
                  ))}
                </tbody>
              </HTMLTable>
            }
          />
          <Route path="/:idModule" element={<EditModulesBlock />} />
        </Routes>
      </div>
      <ModalMainModules editData={editData} setEditData={setEditData} />
    </div>
  );
};

interface IModuleItem extends ICourseMainModule {
  setEditData: (editData: IEditDataMainModules) => void;
}

const ModuleItem: FC<IModuleItem> = ({ setEditData, ...data }) => {
  const regionName = useGetRegionName(data.regionId);

  const onClickEdit = () => {
    setEditData({
      id: data.id,
      regionId: data.regionId,
      status: data.status,
      title: data.title,
    });
  };
  return (
    <tr>
      <td>{data.id}</td>
      <td>{data.title}</td>
      <td>
        <Status status={data.status} />
      </td>
      <td>
        {" "}
        <Tag minimal large intent="primary">
          {regionName}
        </Tag>
      </td>
      <td>
        <Button icon={"edit"} minimal onClick={onClickEdit} />
        <Link to={`/courses/${data.courseId}/modules/${data.id}`}>
          <Button icon={"chevron-right"} minimal />
        </Link>
      </td>
    </tr>
  );
};
