import { Button, Card, Tag } from "@blueprintjs/core";
import { Loading, Status } from "Componens/common";
import { useGetMainModule } from "Hooks/api/courses/mainModules";
import { useUpdateModules } from "Hooks/api/courses/modules";
import { useGetRegionName } from "Hooks/common";
import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ICourseModule } from "Types/courses";
import { AddEditModule } from "./addEdtModule";

import styles from "./index.module.css";
import { ItemModule } from "./ItemModule";
import { ICourseModuleEdit } from "./types";

export const EditModulesBlock: FC = () => {
  const { id: idCourse, idModule } = useParams<string>();
  const { isError, isLoading, data } = useGetMainModule(
    idModule as string,
    idCourse as string
  );

  const regionName = useGetRegionName(data?.regionId || 1);

  const [editData, setEditData] = useState<ICourseModuleEdit | undefined>();

  const openEditModule = (dataModule?: ICourseModuleEdit) => {
    setEditData(dataModule);
    setErrors([]);
  };

  const { mutate, errors, setErrors, isDisabled } =
    useUpdateModules(openEditModule);

  const editModuleHandler = (dataModule: ICourseModuleEdit) => {
    if (!data) return;

    let newModules: (ICourseModuleEdit | ICourseModule)[] = [
      ...data.courseModules,
    ];

    if (dataModule.id !== "new") {
      newModules.splice(dataModule.editIndex, 1);
    }
    newModules.splice(+dataModule.itemNumber - 1, 0, {
      ...dataModule,
      edit: true,
    });

    mutate({
      courseId: idCourse as string,
      data: { modules: newModules, id: idModule as string },
    });
  };

  const navigate = useNavigate();

  if (isError) {
    return null;
  }

  return (
    <div>
      {isLoading ? (
        <Loading fullScreen />
      ) : (
        <div>
          <Card className={styles["row"]}>
            <div>
              <Button
                icon={"arrow-left"}
                minimal
                onClick={() => navigate(-1)}
              />
              ID: {data?.id}
            </div>
            <div>{data?.title}</div>
            <div>
              <Status status={data?.status} />
            </div>
            <div>
              <Tag minimal large intent="primary">
                {regionName}
              </Tag>
            </div>
          </Card>
          <div>
            <h2>
              Modules Course{" "}
              <Button
                minimal
                icon={"add"}
                onClick={() =>
                  openEditModule({
                    id: "new",
                    itemNumber: data ? data.courseModules.length + 1 : 1,
                    title: "",
                    description: "",
                    editIndex: 1,
                    status: "active",
                    edit: true,
                  })
                }
              />
            </h2>
            {data?.courseModules
              .sort((a, b) => a.itemNumber - b.itemNumber)
              .map((item, index) => (
                <ItemModule
                  data={item}
                  index={index}
                  setEditModule={openEditModule}
                  key={item.id + "module"}
                />
              ))}
          </div>
          <AddEditModule
            submitData={editModuleHandler}
            data={editData}
            setEditData={setEditData}
            lengthModules={data ? data.courseModules.length : 1}
            errors={errors}
            isDisabledSave={isDisabled}
          />
        </div>
      )}
    </div>
  );
};
