import axios, { AxiosError } from "axios";
import { API_CONTENT_ID } from "Constants/API/Courses";
import { HttpHeadersAuthorization } from "Helpers/common";
import { IValidErrorRes } from "Types/responce";

interface IContentVaiables {
  courseId: string;
  mainModuleId: number;
  data: any;

  any: { setPersent: any };
}

export const constentServices = {
  async createContent({
    courseId,
    mainModuleId,
    data,
    any: { setPersent },
  }: IContentVaiables) {
    return axios
      .post(API_CONTENT_ID(courseId), data, {
        headers: HttpHeadersAuthorization(),
        onUploadProgress: (progressEvent: {
          loaded: number;
          total: number;
        }) => {
          setPersent(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
      })
      .then(() => ({ courseId, mainModuleId }))
      .catch((e: AxiosError<IValidErrorRes>) => {
        if (e.response?.data.errors) {
          throw e.response?.data.errors;
        }
        throw e;
      });
  },
  async updateContent({
    courseId,
    mainModuleId,
    data,
    any: { setPersent },
  }: IContentVaiables) {
    return axios
      .put(API_CONTENT_ID(courseId), data, {
        headers: HttpHeadersAuthorization(),
        onUploadProgress: (progressEvent: {
          loaded: number;
          total: number;
        }) => {
          setPersent(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
      })
      .then(() => ({ courseId, mainModuleId }))
      .catch((e: AxiosError<IValidErrorRes>) => {
        if (e.response?.data.errors) {
          throw e.response?.data.errors;
        }
        throw e;
      });
  },
  async deleteContent(
    courseId: string,
    contentId: number,
    mainModuleId: number
  ) {
    return axios
      .delete(API_CONTENT_ID(courseId) + "/" + contentId, {
        headers: HttpHeadersAuthorization(),
      })
      .then(() => ({
        courseId,
        mainModuleId,
      }))
      .catch((e) => {
        throw e;
      });
  },
};
