import { FileInput, HTMLSelect } from "@blueprintjs/core";
import { FC, useContext } from "react";

import { FileContext } from "Pages/AdminPages/Courses/CourseItem";
import { IEditContent } from "../../types";

interface FileContentModalProps {
  data: IEditContent;
  onSelectFile: React.ChangeEventHandler<HTMLSelectElement>;
  isDisabled: boolean;
  error: (name: string) =>
    | {
        helperText: string;
        intent: any;
      }
    | undefined;
  onChangeFile: React.FormEventHandler<HTMLInputElement>;
}

export const FileContentModal: FC<FileContentModalProps> = ({
  data,
  onSelectFile,
  isDisabled,
  error,
  onChangeFile,
}) => {
  const files = useContext(FileContext);

  return (
    <div>
      <HTMLSelect
        id="fileId"
        fill
        value={data?.fileId}
        onChange={onSelectFile}
        disabled={isDisabled}
        {...error("data")}
      >
        {files
          .filter((item) => item.type === data?.type)
          .map((item, index) => (
            <option value={item.id} key={index + "fileId"}>
              {item.title}
            </option>
          ))}
        <option value={"ADDFILE"}>Add File</option>
      </HTMLSelect>
      {data?.fileId === "ADDFILE" && (
        <FileInput
          fill
          id="img"
          onInputChange={onChangeFile}
          hasSelection={!!data?.data}
          text={
            typeof data?.data === "object"
              ? data.data.name
              : data?.data
              ? data.data.split("/").pop()
              : "Choose file"
          }
          {...error("data")}
          disabled={isDisabled}
        />
      )}
    </div>
  );
};
