import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Switch,
} from "@blueprintjs/core";
import { useAddCourse } from "Hooks/api/courses";
import { FC, useCallback } from "react";
import { IAddCourse } from "./constants";

interface IModalMainCourse {
  isOpen: boolean;
  data: IAddCourse | undefined;
  setData: React.Dispatch<React.SetStateAction<IAddCourse | undefined>>;
}

export const ModalMainCourse: FC<IModalMainCourse> = ({
  isOpen,
  data,
  setData,
}) => {
  const onCloseWrapper = () => {
    if (!isDisabled) {
      setData(undefined);
    }
  };

  const { isDisabled, errors, mutate } = useAddCourse(
    onCloseWrapper,
    !data?.id
  );

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (data) {
      setData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const onChecked = () => {
    if (data) {
      setData({ ...data, regionOnly: !data.regionOnly });
    }
  };

  const onCheckedAnnounce = () => {
    if (data) {
      setData({ ...data, isAnnouncement: !data.isAnnouncement });
    }
  };

  const onSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (data) {
      setData({ ...data, [e.target.id]: e.target.value });
    }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (!data) return;
    mutate(data);
  };

  const error = useCallback(
    (name: string) => {
      const error = errors.find((e) => e.name === name);
      if (error) {
        return { helperText: error.message, intent: "danger" as any };
      }
      return;
    },
    [errors]
  );

  return (
    <Dialog
      title={data?.id ? "Edit Course" : "Add course"}
      isOpen={isOpen}
      onClose={onCloseWrapper}
    >
      <form onSubmit={onSubmit}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label={"Title"} {...error("title")} labelFor={"title"}>
            <InputGroup
              disabled={isDisabled}
              value={data?.title || ""}
              onChange={onChange}
              id={"title"}
              {...error("title")}
            />
          </FormGroup>
          <Switch
            disabled={isDisabled}
            label="Region Only"
            checked={data?.regionOnly}
            id={"regionOnly"}
            onChange={onChecked}
            {...error("regionOnly")}
          />
          <FormGroup label={"Type"} {...error("type")} labelFor={"type"}>
            <HTMLSelect
              id="type"
              fill
              value={data?.type}
              onChange={onSelect}
              disabled={isDisabled}
              {...error("type")}
            >
              <option value={"course"}>Course</option>
              <option value={"vebinar"}>Vebinar</option>
              <option value={"livetalk"}>Livetalk</option>
            </HTMLSelect>
          </FormGroup>
          <Switch
            disabled={isDisabled}
            label="Announcement"
            checked={data?.isAnnouncement}
            id={"isAnnouncement"}
            onChange={onCheckedAnnounce}
            {...error("isAnnouncement")}
          />
          {data?.id && (
            <FormGroup
              label={"Status"}
              {...error("status")}
              labelFor={"status"}
            >
              <HTMLSelect
                id="status"
                fill
                value={data?.status}
                onChange={onSelect}
                disabled={isDisabled}
                {...error("status")}
              >
                <option value={"active"}>Active</option>
                <option value={"draft"}>Draft</option>
                <option value={"closed"}>Closed</option>
              </HTMLSelect>
            </FormGroup>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={onCloseWrapper}
              intent={"danger"}
              type={"button"}
              disabled={isDisabled}
            >
              Close
            </Button>

            <Button icon={"saved"} type={"submit"} disabled={isDisabled}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
