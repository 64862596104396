import { API_ANNOUNCE } from "Constants/API/Courses";
import { HttpHeadersAuthorization } from "Helpers/common";
import { IValidErrorRes } from "Types/responce";
import axios, { AxiosError } from "axios";

export const AnnounceServices = {
  async addAnnounce(courceId: string, data: any) {
    return axios
      .post(API_ANNOUNCE(courceId), setJsonOrFormData(data), {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => courceId)
      .catch((e: AxiosError<IValidErrorRes>) => {
        throw e.response?.data.errors;
      });
  },
  async editAnnounce(courceId: string, data: any) {
    return axios
      .put(API_ANNOUNCE(courceId), setJsonOrFormData(data), {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => courceId)
      .catch((e: AxiosError<IValidErrorRes>) => {
        throw e.response?.data.errors;
      });
  },
  async deleteAnnounce(courceId: string, announceId: number) {
    return axios
      .delete(API_ANNOUNCE(courceId) + "/" + announceId, {
        headers: HttpHeadersAuthorization(),
      })
      .then((res) => courceId)
      .catch((e: AxiosError) => {
        throw e;
      });
  },
};

function setJsonOrFormData(data: any) {
  if (typeof data.img === "object") {
    const formData = new FormData();

    Object.entries(data).forEach((item) => {
      const [key, value] = item;
      formData.append(key, value as string);
    });

    return formData;
  }
  return data;
}
