import { Tag, Intent } from "@blueprintjs/core";
import { FC } from "react";

import { TСourseStatus } from "Types/common";

import styles from "./index.module.css";

interface IStatus {
  status?: TСourseStatus;
  statusCustom?: string;
  TagNamesCustom?: { [key: string]: string };
  TagsIntentCustom?: { [key: string]: Intent };
}

export const Status: FC<IStatus> = ({
  status = "active",
  statusCustom,
  TagNamesCustom,
  TagsIntentCustom,
}) => {
  const TagsIntent = {
    active: "success",
    draft: "warning",
    closed: "danger",
  };

  const TagsName = {
    active: "Active",
    draft: "Draft",
    closed: "Closed",
  };

  return (
    <Tag
      intent={
        TagsIntentCustom && statusCustom
          ? (TagsIntentCustom[statusCustom] as Intent) ||
            (TagsIntent[status] as Intent)
          : (TagsIntent[status] as Intent)
      }
      className={styles["tag"]}
      minimal
      large
    >
      {TagNamesCustom && statusCustom
        ? TagNamesCustom[statusCustom]
        : TagsName[status]}
    </Tag>
  );
};
